import React, { useState } from "react";
import "./FleetTracking.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import group79 from "../../res/images/Group 79.png";
import group80 from "../../res/images/Group 80.png";
import group82 from "../../res/images/Group 82.png";
import group83 from "../../res/images/Group 83.png";
import group91 from "../../res/images/Group 91.png";
import laptop from "../../res/images/feelt tracking.png";
import { Modal, Button } from "react-bootstrap";
import excel from "../../res/images/excel.png"

const FT = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />
      <div className="strategy-container-ft">
        <div className="strategy-text-ft">
          <h1 className="fleet-safety">
            Revolutionizing Fleet Safety with Real-Time Dead Zone Tracking
          </h1>
          <p className="ft-subheading">
            Leveraging IoT Analytics for Unmatched Visibility and Efficiency in
            Fleet Operations
          </p>

          <div className="d-flex" style={{ marginTop: "12vw" }}>
            <img src={group79} height={100} width={100} alt="g"></img>
            <p className="ft-s-s-heading" style={{ marginLeft: "2vw" }}>
              Fleet operators face significant challenges in maintaining vehicle
              connectivity and safety in remote or dead zones, leading to
              potential operational inefficiencies, safety risks, and
              communication breakdowns. 
            </p>
          </div>
        </div>
      </div>

      <div className="row px iot-based" style={{backgroundColor:"#FAFAFA"}}>
        <div className="col-6 d-flex align-items-center">
          <img
            onClick={handleShow}
            src={laptop}
            height={350}
            width={350}
            alt="lp"
            style={{cursor:"pointer"}}
          ></img>
        </div>
        <div className="col-lg-6 col-md-6">
          <p className="edge-cut">
            A CUTTING-EDGE <br />
            IOT-BASED DASHBOARD
          </p>
          <div className="d-flex">
            <img src={group80} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Provides real-time tracking and analysis of vehicles entering dead
              zones
            </p>
          </div>

          <div className="d-flex">
            <img src={group80} alt="81"></img>

            <p style={{ marginLeft: "1vw" }}>
              Identifies the most common dead zones, measures time and distance
              spent in these areas, and highlights the routes most frequently
              affected
            </p>
          </div>

          <div className="d-flex">
            <img src={group82} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Helps fleet managers optimize routes by leveraging advanced
              analytics
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={group83} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Enhances overall operational efficiency by improving communication
            </p>
          </div>

          <div>
            <img src={excel} height={90} width={400} alt="excel"></img>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Fleet Tracking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            style={{ width: "100%", height: "700px" }}
            frameBorder="0"
            allowFullScreen="true"
            src="https://app.powerbi.com/view?r=eyJrIjoiYmQ1YjM1NDItOTgyYS00OWI1LWFiMDgtYjc4ZDIzYjRlOWU5IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
            title="VideoToText"
          />
        </Modal.Body>
      </Modal>

      {/* Steps involved */}
      <div className="row px mt-7 " style={{ marginBottom: "2vw" }}>
        <div className="col-6">
          <p className="fi">
            FUTURE INDUSTRY
            <br />
            USES & APPLICATIONS
          </p>

          <div>
            <p className="sc">SMART CITY INTEGRATION</p>
            <p className="sc-sub">
              Incorporating dead zone data into broader smart city
              infrastructure for enhanced urban planning and traffic management.
            </p>
          </div>

          <div>
            <p className="sc">PREDICTIVE ANALYTICS</p>
            <p className="sc-sub">
              {" "}
              Utilizing historical data to predict future dead zones and
              mitigate risks proactively.
            </p>
          </div>

          <div>
            <p className="sc">CROSS INDUSTRY APPLICATIONS</p>
            <p className="sc-sub">
              Expanding technology to other industries such as logistics,
              emergency services, and public transportation to improve safety
              and efficiency.
            </p>
          </div>
        </div>
        <div className="col-6 d-flex align-items-center">
          <img className="w-100" src={group91} alt="91" />
        </div>
      </div>
    </>
  );
};

export default FT;
