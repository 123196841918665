import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../config";

export default function GuestUserRoute({
  component: Component,
  // user,
  ...rest
}) {
  const user = JSON.parse(localStorage.getItem(config.keys.user));
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        user && user.id ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect to={config.routes.login} />
        )
      }
    />
  );
}
