import React from "react";
import ReactTableau from "tableau-react";

export const Tableau = ({ url, options = {}, query = "" }) => {
  // const ref = useRef(null);

  // const initDash = () => {
  //   var viz, url, options;
  //   url = "http://public.tableau.com/views/RegionalSampleWorkbook/Storms";
  // };

  return (
    <ReactTableau
      url={url}
      options={{ ...options, device: "desktop" }}
      query={query}

      // token="<TRUSTED TICKET HERE>"
    />
  );
};
