import config from "../config";
import axiosObj from "../config/axios";
import axios from "../config/axios";

export const getAllUsers = () => {
  axiosObj.setAuthHeader();
  return axios.get(config.apiEndPoint + "/admin/getUsers");
};

export const getAdminRequests = () => {
  axiosObj.setAuthHeader();
  return axios.get(config.apiEndPoint + "/admin/get-admin-approval-request");
};
export const getAllDashboards = () => {
  axiosObj.setAuthHeader();
  return axios.get(config.apiEndPoint + "/admin/getDashboards");
};
export const getStoreCodes = () => {
  axiosObj.setAuthHeader();
  return axios.get(config.apiEndPoint + "/admin/getStoreCodes");
};
export const getStoreByCode = (data) => {
  axiosObj.setAuthHeader();
  return axios.post(config.apiEndPoint + "/admin/getStoreByCode", data);
};
export const getStoreByName = (data) => {
  axiosObj.setAuthHeader();
  return axios.post(config.apiEndPoint + "/admin/getStoreByName", data);
};
export const createUser = (user) => {
  axiosObj.setAuthHeader();
  return axios.post(config.apiEndPoint + "/admin/createUser", user);
};
export const deleteUser = (userId) => {
  axiosObj.setAuthHeader();
  return axios.delete(config.apiEndPoint + "/admin/deleteUser", {
    data: { userId },
  });
};
export const acceptChanges = (data) => {
  axiosObj.setAuthHeader();
  return axios.put(config.apiEndPoint + "/admin/acceptChanges", data);
};
