import React, { useEffect, useState } from "react";
import "./Lulusar.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import rectangle from "../../res/images/Rectangle 34.png";
import bordertop from "../../res/images/borderfull.png";
import group72 from "../../res/images/Group 72.png";
import group73 from "../../res/images/Group 73.png";
import group74 from "../../res/images/Group 74.png";
import group77 from "../../res/images/Group 76.png";
import group76 from "../../res/images/Group 77.png";
import group78 from "../../res/images/Group 78.png";
import group67 from "../../res/images/Group 67.png";
import amazonrds from "../../res/images/amazonrds.png";
import nodejs from "../../res/images/nodejs.png";
import reactjs from "../../res/images/reactjs.png"
import tableau from "../../res/images/tableau.png"
import metabase from "../../res/images/metabase.png"
import SideBar from "../Drawer/SideBar";
import { Modal, Button } from "react-bootstrap";
import fullscreen from '../../res/images/fullscreen.png'
import p1 from "../../res/images/p1.png"
import p2 from "../../res/images/p2.png"
import p3 from "../../res/images/p3.png"

const Lulusar = () => {

  const [activeTab, setActiveTab] = useState("Dashboard"); // Default to "dashboard"

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    // Ensure the Tableau script is loaded
    const script = document.createElement('script');
    script.type = 'module';
    script.src = "https://online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
    document.body.appendChild(script);
}, []);
 

  return (
    <>
      <MegaHeader />

      <div className="row px mt-5">
        <img src={bordertop} alt="bordertop" className="mt-5 mb-3"></img>
        <div className="col-8">
          <p className="misguided">
            MIS-
            <br />
            GUIDED BUSINESS DECISION
          </p>
        </div>
        <div className="col-4">
          <p className="disparate">
            Disparate sources of data leading to misguided business decisions
            and missed market opportunities​
          </p>
          <div
            className="px-2 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <img src={group72} className="iconhw" alt="g72"></img>
            <img src={group73} className="iconhw" alt="g73"></img>
            <img src={group74} className="iconhw" alt="g74"></img>
          </div>
        </div>
      </div>

      

      <div className="row px mt-5">
        <div className="col-8">
          <img src={rectangle} alt="rectangle"></img>
          <p className="Retail mt-3 pl-30">Retail and e-commerce</p>
          <p className="f-20 pl-30">
            Functional and affordable fast fashion brand for modern and
            independent woman.
            <br />
            11 stores in Pakistan and online presence across 5 different
            countries.
          </p>
        </div>
      </div>


      <div className="row mt-7 px">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <button 
                            className={`tab-button ${activeTab === 'Dashboard' ? 'active' : ''}`} 
                            onClick={() => handleTabChange("Dashboard")}>
                            Dashboard
                        </button>
                        <button 
                            className={`tab-button ${activeTab === 'pulse' ? 'active' : ''}`} 
                            onClick={() => handleTabChange("pulse")}>
                            Pulse
                        </button>
                    </div>
                    <img 
                        src={fullscreen} 
                        className="mb-2 fullscreen" 
                        alt="full-screen" 
                        height={20} 
                        width={20} 
                        onClick={handleShow}
                    />
                </div>

                <div className="col-12">
                    {activeTab === "Dashboard" ? (
                        <iframe 
                            src="https://public.tableau.com/views/POCTesting/Overview-Combined?%3AshowVizHome=no&%3Aembed=true" 
                            width="100%" 
                            height="1200px" 
                            frameBorder="0" 
                            title="dashboard"
                            allowFullScreen>
                        </iframe>
                    ) : (
                        <div className="pulse-images">
                            <img src={p1} alt="p1" />
                            <img src={p2} alt="p1" />
                            <img src={p3} alt="p1" />
                        </div>
                    )}
                </div>
            </div>



      {/* <div className="row mt-7 px">
      <div className="d-flex">
          <img src={fullscreen} className="mb-2 fullscreen" alt="full-screen" height={20} width={20} 
          onClick={handleShow}
          ></img>
       
        </div>
        <div className="col-12">
     
          
        <iframe 
  src="https://public.tableau.com/views/POCTesting/Product-Combined?:showVizHome=no&:embed=true" 
  width="100%" 
  height="1200px" 
  frameborder="0" 
  title="dashboard"
  allowfullscreen>
</iframe>

        </div>
      </div> */}

      <Modal show={showModal} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Data Pilot Analytics Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe 
  src="https://public.tableau.com/views/POCTesting/Product-Combined?:showVizHome=no&:embed=true" 
  width="100%" 
  height="1200px" 
  frameborder="0" 
  title="dashboard"
  allowfullscreen>
</iframe>
        </Modal.Body>
      </Modal>


      {/* Steps involved */}
      <div className="row px mt-7">
        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group77} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Developing a data extraction and transformation process where all
            the data for both retail and online sales can be found in one single
            source. The data was consolidated from various sources, such as the
            company’s website, social media, and Google Ads.
          </p>
        </div>

        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group76} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Consolidated data was stored in an AWS database, where it would be
            used to help analyze the effectiveness of each marketing and sales
            channel.
          </p>
        </div>

        <div className="col-4" style={{ display: "flex" }}>
          <img src={group78} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Custom dashboards to display retail and online sales data side by
            side. Each dashboard was designed to be clear and uncluttered, with
            no more than 4-5 widgets. The dashboards allowed users to view
            specific numbers and filter the data by time range, brand, and
            city.  
          </p>
        </div>
      </div>



      <div className="row px mt-7 justify-content-between ">
<img src={amazonrds} style={{height:"65px", width:"170px"}} alt="angular"></img>
<img src={nodejs} style={{height:"60px", width:"160px"}} alt="angular"></img>
<img src={reactjs}  style={{height:"60px", width:"160px"}}  alt="angular"></img>
<img src={tableau}  style={{height:"60px", width:"170px"}} alt="angular"></img>
<img src={metabase}  style={{height:"60px", width:"150px"}} alt="angular"></img>
</div>




      <div className="row px mt-7">
        <div className="col-2">
          <img src={rectangle} alt="rect" className="w-100"></img>
          <p className="impact">Impact</p>
        </div>
      </div>

      <div className="row mt-7">
        <div
          className="col-7"
          style={{ paddingLeft: "10vw", paddingRight: "5vw" }}
        >
          <div>
            <p className="cheading">Comprehensive Analytics</p>
            <p className="f-26">
              Comprehensive analytics across departments improved
              decision-making speed by 40% 
            </p>
          </div>

          <div>
            <p className="cheading">Strategic Planning</p>
            <p className="f-26">
              The solution allowed for quicker and more informed strategic
              planning, which helped reduce lost revenue by 30%
            </p>
          </div>

          <div>
            <p className="cheading">Top-performing products</p>
            <p className="f-26">
              The platform provided valuable insights into which designs and
              products performed better in specific regions, resulting in a 30%
              increase in sales of top-performing products
            </p>
          </div>

          <div>
            <p className="cheading">Optimized Spending</p>
            <p className="f-26">
              By analyzing the return on ad spend (ROAS), the company could
              predict and optimize spending on paid media, leading to a 35%
              increase in return on marketing investment
            </p>
          </div>
        </div>

        <div className="col-5">
          <img src={group67} alt="g67" className="w-100"></img>
        </div>
      </div>
    </>
  );
};

export default Lulusar;
