import React from "react";
import "./PswComponent.css";
import MegaHeader from "../mega-header";
import PSW from "../../res/images/psw.png";
import "bootstrap/dist/css/bootstrap.min.css";
import chess from "../../res/images/chess.png";
import sol from "../../res/images/solution.png";
import understandsection from "../../res/images/understandsection.png";
import mob from "../../res/images/mob.png";
import Group35 from "../../res/images/Group 35.png";
import understand from "../../res/images/Understand.png";
const PswComponent = () => {
  return (
    <>
      <MegaHeader />
      <div className="strategy-container">
        <div>
          <img src={Group35} alt="g35"></img>
          <p className="font-righteous" style={{ marginBottom: "0px" }}>
            Creating a Data Strategy for a trading company to optimize its data
          </p>
          <p className="font-righteous" style={{ marginTop: "0px" }}>
            assets and boost trade efficiency and innovation
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <img src={PSW} alt="psw" className="PSW"></img>
        </div>
      </div>

      <div className="row px">
        <div className="col-lg-12">
          <div className="pr">
            <h3 className="main-heading">
              Committed to facilitating international trade and to make doing
              business in the country easier.
            </h3>
            <p className="p-text">
              The Pakistan Single Window (PSW) Company was created to simplify
              trade and enhance the efficiency of cross-border transactions. By
              integrating various government agencies and private sector
              entities, PSW aims to streamline import, export, and transit
              processes, reducing both time and costs associated with trade. 
            </p>
          </div>
        </div>
      </div>

      <div className="row align-items-center mt-7 bt">
        <div className="col-3 ">
          <img src={chess} alt="chess"></img>
        </div>
        <div className="col-8">
          <p className="ml-1 px-4">
            <span className="bold-25">
              PSW was unable to generate value from its data assets.
            </span>
            <span className="normal-25">
              This stemmed mainly from the absence of a standardized data
              management framework, leading to data silos across various
              departments. 
            </span>

            <span className="bold-25">
              Coupled with data silos, the company struggles with data quality
              issues, compliance risks, and an inability to leverage meaningful
              data for strategic decision-making.
            </span>
            <span className="normal-25">
              This hamstrung the company from fully optimizing the use of its
              data to innovate and maintain a competitive edge.  
            </span>
          </p>
        </div>
      </div>

      <div className="row mt-7 px ">
        <div className="col-6 ">
          <p className="bold-20">
            Before building the company’s data strategy, Data Pilot first fully
            understood the organization, its goals and the complexities of
            cross-border trade.  
          </p>
        </div>

        <div className="col-12 ">
          <img src={sol} alt="sol"></img>
        </div>
      </div>

      <div className="row  mt-7 py-5">
        <div className="col-2 bg-white"></div>

        <div className="col-8 bg-img  py-5 px-0">
      <div className="usimg"></div>
        </div>
        <div className="col-2 image-alignment bg-img"></div>

        <div className="col-2"></div>
        <div className="col-4 bg-img">
          <p style={{ color: "white" }} className="plrb">
            <span className="bold-22">
              Data Management Maturity Assessment
              <br />
            </span>
            <span className="normal-22">
              For the company’s data maturity assessment, we used the DAMA and
              CMMI frameworks, which are widely accepted in the industry. It
              provided both qualitative and quantitative insights into the
              company’s current state of data maturity 
            </span>
          </p>
        </div>
        <div className="col-4 bg-img">
          <p style={{ color: "white"}} className="plrb">
            <span className="bold-22">
              {" "}
              Big Data Solution Architecture Assessment{" "}
            </span>{" "}
            <br />
            <span className="normal-22">
              By relying on the maturity assessment and insights from the
              stakeholders, we proposed various options for Oracle-based
              architectures to address architectural gaps such as the lack of
              tools for MLOps and real-time analytics. 
            </span>
          </p>
        </div>
        <div className="col-2 bg-img"></div>
      </div>

      <div className="row px mt-7">
        <div className="col">
          <img src={mob} alt="mob"></img>
        </div>
        <div className="col">
          <span className="bold-25">
            Data Strategy and Architecture Implementation Roadmap
          </span>
          <p className="normal-25">
            Data Pilot built a comprehensive 314-page data strategy guide that
            pinpointed how the company can leverage its data and resources to
            move to a tangible state of growth. 
          </p>
          <span className="bold-25">API Monetization Model</span>
          <p className="normal-25">
            Alongside giving guidance, Data Pilot also built an API monetization
            model that would enable the company to generate revenue from its
            data.
          </p>
          <p className="normal-20">
            Through these key deliverables, Data Pilot built a comprehensive
            data strategy that would enable the company to utilize its data to
            enhance its operational efficiency and generate revenue from its
            data. 
          </p>
        </div>
      </div>

      <div className="row mt-7" style={{ paddingLeft: "10vw" }}>
        <div className="col">
          <p className="normal-25">
            The project's success was mainly measured by client feedback and
            their acceptance of our gap analysis and recommendations. 
          </p>

          <p className="normal-25">
            The recommendations curated by our team were widely accepted with
            minimal resistance from the company. Through a continuous
            back-and-forth communication, Data Pilot iteratively modified the
            data strategy by incorporating client feedback and having detailed
            discussions, particularly with the technical teams.
          </p>

          <p className="normal-25">
            While designing this ready-to-use strategy, the company was
            empowered to optimally utilize what it has and build upon its
            existing technologies, practices, and systems to continue
            facilitating Pakistan’s trade ecosystem. 
          </p>
        </div>

        <div className="col">
          <img src={mob} alt="mob" style={{ float: "right" }}></img>
        </div>
      </div>
    </>
  );
};

export default PswComponent;
