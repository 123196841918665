const config = {
  apiEndPoint:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL,
  routes: {
    login: "/",
    dashboard: "/dashboard",
    maindashboard: "/maindashboard",
    maindashboards: "/dashboards-detail",
    rpa: "/roboticprocessautomation",
    predictiveanalytics:"/predictiveanalytics",
    videototext: "/videototext",
    marketinganalytics:"/marketinganalytics",
    b2bmarketinganalytics:"/b2bmarketinganalytics",
    ocr: "/ocr",
    computervision:"/computervision",
    creditriskmodel:"/creditriskmodel",
    customersegmentation:"/customersegmentation",
    users: "/users",
    addUser: "/addUser",
    manageRequests: "/manage-request",
    PSW:"/Trades-Analytics",
    Elm:"/Computer-Vision",
    GenAi:"/GenAi",
    OcrCaseStudy:"/OCR",
    Lulusar:"/Retail-Store-Analytics",
    FTRoute:"/Fleet-Tracking",
    Levis:"/Garment-Industry",
    Hubspot:"/Hubspot",
    LinkedInRoute:"/LinkedIn",
    MarketingAnalytics:"/Marketing-Analytics",
    DataPilotStackOverFlow:"/DataPilot-Stack-Overflow",
    PaidOrganicMedia:"/Paid-and-Organic-media-analytics",
    Churn:"/Churn-Prediction-Analytics"
  },
  keys: {
    token: "token",
    user: "user",
    tab: "tab",
  },
metabasedashboard:{
  MainDashboard:1,
  InventoryAndShippingDashboard:2,
  LinkedInPageInsight:4,
  EmailMarketingAnalytics:9,
  WebsiteAnalytics:8,
  HubspotAnalytics:7,
  GoogleAdsAnalytics:6,
  LinkedInAdsAnalytics:5
},
metabasedashboardheight:{
  MainDashboard:"5000px",
  InventoryAndShippingDashboard:"5000px"
},


  dashboardInfo: [
    {
      name: "PaidMediaSummary",
      label: "Paid Media Summary",
      type: "tableau",
      url: "https://public.tableau.com/views/PaidMediaSummary/PaidMediaSummary",
    },
    {
      name: "Dashboard1",
      label: "Channel Analytics-Tableau",
      type: "tableau",
      url: "https://public.tableau.com/app/profile/data.pilot/viz/Channel_Grabber/Dashboard1?publish=yes",
    },
    {
      name: "InventoryShipping",
      label: "Stock Analytics-Tableau",
      type: "tableau",
      url: "https://public.tableau.com/app/profile/data.pilot/viz/InventoryShipping/InventoryShipping?publish=yes",
    },
    {
      name: "Dashboard1",
      label: "Dashboard1",
      type: "tableau",
      url: "http://52.66.207.103:3000/dashboard/1-main-dashboard?currency=GBP",
    },
    {
      name: "Dashboard2",
      label: "Dashboard02",
      type: "tableau",
      url: "http://52.66.207.103:3000/dashboard/2-inventory-shipping",
    },
    {
      name: "MarketingSpendAnalytics",
      label: "Marketing Spend Analytics",
      type: "tableau",
      url: "https://public.tableau.com/views/MarketingSpendAnalytics_16593622561090/MarketingSpendAnalsis",
    },
    {
      name: "PaidCampaignComparison",
      label: "Paid Campaign Comparison",
      type: "tableau",
      url: "https://public.tableau.com/views/PaidCampaignComparison/PaidCampaignComparison",
    },
    {
      name: "Paid Campaign Analytics",
      label: "Paid Campaign Analytics ",
      type: "tableau",
      url: "https://public.tableau.com/views/PaidCampaignAnalysis/PaidCampaingAnalysis",
    },
    {
      name: "PaidCampaignMetricsAnalysis",
      label: "Organic Media Summary",
      type: "tableau",
      url: "https://public.tableau.com/views/OrganicMediaSummary/OrganicMediaSummary",
    },
    {
      name: "OrganicPostComparison",
      label: "Organic Post Comparison",
      type: "tableau",
      url: "https://public.tableau.com/views/OrganicPostComparison/OrganicPostComparison",
    },
    {
      name: "OrganicPostAnalytics",
      label: "Organic Post Analytics",
      type: "tableau",
      url: "https://public.tableau.com/views/OrganicPostAnalytics/OrganicPostAnalytics",
    },
  ],

};
export default config;
