import { makeStyles } from "@material-ui/core/styles";
import bg7 from "../res/images/bg7.jpeg";
const useGlobalStyles = makeStyles((theme) => ({
  container: {
    
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
   
  },
  
  h3:{
    textAlign:'center'
  },
  paper: {
    // paddingTop:"20px",
    padding: theme.spacing(4),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%",
    opacity:"0.8",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundColor:"#63687b",
    // backgroundColor:"transparent",
    backgroundImage: `url(${bg7})`,
    //backgroundColor:"#525252",

    borderRadius:"50px",
    border:"3px solid",
    borderColor:"#636466"
    
    
  },




  
  fixedHeight: {
    height: 240,
  },
  deleteBtnContained: {
    backgroundColor: theme.palette.error.main,
    color: "#fff !important",
    "&:hover": {
      backgroundColor: theme.palette.error.light + "!important",
    },
  },
  deleteBtn: {
    color: theme.palette.error.main,
    // color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.light + "!important",
      color: "#fff",
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  filledIconBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      opacity: 0.8,
    },
  },
}));

export default useGlobalStyles;