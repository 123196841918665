
import dplogo from '../res/images/Group 31.png'
import circularuser from '../res/images/circle-user.png' 
import { useHistory } from "react-router";
import config from "../config";
import './navbar.css'
import { Modal,  } from "react-bootstrap";
import { useEffect, useState } from 'react';
const MegaHeader = () =>{

    
    

    const [boxShadowColor, setBoxShadowColor] = useState('rgba(255, 255, 255, 0.5)');

    // Function to generate a random RGB color
    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 1)`;
    };

    useEffect(() => {
        // Change the color every 1 second (1000 ms)
        const interval = setInterval(() => {
            setBoxShadowColor(getRandomColor());
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);


    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
  
    const handleShow = () => setShowModal(true);
    const history = useHistory();

    return(
        <>
         <header style={{position:"fixed" , zIndex:"1000", width:"100%" ,top:0}} >
        <nav className="navbar navbar-expand-lg sticky-navbar p-0">
            <div className="container">
             <img src={dplogo} alt="logo" height={40} width={45}
              onClick={(event) =>{
                event.stopPropagation();
                history.push(config.routes.maindashboard)
              }}
             ></img>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbar-content">
                    <i className="fas fa-stream text-white"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbar-content">
                    <ul className="navbar-nav mx-5 mb-2 mb-lg-0">
                      
                     
                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link my-2"  
                                data-bs-auto-close="outside">AI & Data Analytics </a>
                            <div className="dropdown-menu shadow ">
                                <div className="mega-content px-md-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4"
                                                style={{marginLeft:"8vw"}}>
                                                {/* <h5>AI & Data Analytics </h5> */}
                                                <div className="list-group"
                                                
                                                >
                                                    <a className="list-group-item" href="/GenAi">Generative AI (GenAI) Implementation </a>
                                                    <a className="list-group-item" href="/OCR">Optical Character Recognition (OCR) Solutions</a>
                                                    <a className="list-group-item" href="/Paid-and-Organic-media-analytics">Paid and Organic Media Analytics</a>
                                                    <a className="list-group-item" href="/LinkedIn">LinkedIn Analytics Integration </a>
                                                    <a className="list-group-item" href="/Hubspot">HubSpot Email Statistics </a>
                                                    <a className="list-group-item" href="/Churn-Prediction-Analytics">Churn Prediction Analytics </a>
                                                </div>
                                            </div>
                                        
                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link my-2"  
                                data-bs-auto-close="outside">Retail & E-Commerce</a>
                            <div className="dropdown-menu shadow">
                                <div className="mega-content px-md-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                        <div className="col-lg-2 col-sm-6 py-4 px-xl-5 px-4"
                                        
                                        >
                                       
                                            {/* <div className="list-group">
                                                <a className="list-group-item" href="#">Lulusar Data Transformation </a>
                                                <a className="list-group-item" href="#">Levi's AI Integration</a>
                                                <a className="list-group-item" href="#">Government & Public Sector Solutions</a>
                                                <a className="list-group-item" href="#">Pakistan Single Window (PSW) </a>
                                                <a className="list-group-item" href="#">ELM</a>
                                            </div> */}
                                        </div>
                                           
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4"
                                        style={{marginLeft:"4vw"}}
                                            >
                                                {/* <h5>Retail & E-Commerce </h5> */}
                                                <div className="list-group">
                                                    <a className="list-group-item" href="/Retail-Store-Analytics">Omni-Channel Analytics </a>
                                                    <a className="list-group-item" href="/Garment-Industry">AI Defect Detection</a>
                                              
                                              
                                                    <a className="list-group-item" href="/Computer-Vision">ELM</a>
                                                </div>
                                            </div>
                                          
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link my-2"  
                                data-bs-auto-close="outside">Logistics & Utilities </a>
                            <div className="dropdown-menu shadow">
                                <div className="mega-content px-md-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                        <div className="col-lg-4 col-sm-6 py-4 px-xl-5 px-4">
                                           
                                                {/* <div className="list-group">
                                                    <a className="list-group-item" href="#">Fleet Tracking & Dead-Zone Mapping</a>
                                                    <a className="list-group-item" href="#">K-Electric Data Analytics </a>
                                                
                                                </div> */}
                                            </div>
                                           
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4"
                                            style={{marginLeft:"0.6vw"}}
                                            >
                                                {/* <h5>Logistics & Utilities </h5> */}
                                                <div className="list-group">
                                                    <a className="list-group-item" href="/Fleet-Tracking">Fleet Tracking & Dead-Zone Mapping</a>
                                                    {/* <a className="list-group-item" href="#">K-Electric Data Analytics </a> */}
                                                
                                                </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>




                        <li className="nav-item dropdown dropdown-mega position-static">
                            <a className="nav-link my-2"  
                                data-bs-auto-close="outside">Strategy and Growth </a>
                            <div className="dropdown-menu shadow">
                                <div className="mega-content px-md-4">
                                    <div className="container-fluid">
                                        <div className="row">
                                        <div className="col-lg-5 col-sm-6 py-4 px-xl-5 px-4">
                                           
                                                {/* <div className="list-group">
                                                    <a className="list-group-item" href="#">Fleet Tracking & Dead-Zone Mapping</a>
                                                    <a className="list-group-item" href="#">K-Electric Data Analytics </a>
                                                
                                                </div> */}
                                            </div>
                                           
                                            <div className="col-lg-3 col-sm-6 py-4 px-xl-5 px-4"
                                            style={{marginLeft:"4vw"}}
                                            >
                                                {/* <h5>Logistics & Utilities </h5> */}
                                                <div className="list-group">
                                                <a className="list-group-item" href="/Trades-Analytics">Pakistan Single Window (PSW) </a>
                                                    {/* <a className="list-group-item" href="#">K-Electric Data Analytics </a> */}
                                                
                                                </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                       
                        
                    </ul>
                
                </div>
              <div className='d-flex'>
                <button onClick={handleShow} className="glow-button mx-3"
                  style={{
                    boxShadow: `0 0 10px ${boxShadowColor}, 0 0 20px ${boxShadowColor}, 0 0 30px ${boxShadowColor}`
                }}
                
                >Subscribe</button>
              <img src={circularuser} alt="circular-user" className="d-none d-lg-block"></img>
              </div>
            </div>
        </nav>
    </header>



    <Modal show={showModal} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Join the Data Pilot Revolution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{ height: '600px', width: '100%' }}>
                    <iframe
                        src="https://share.hsforms.com/1g3wzZJv8SCKDQsYm7P_QlAco659"
                        title="Embedded Form"
                        style={{ height: '100%', width: '100%', border: 'none' }}
                        allowFullScreen
                    ></iframe>
                </div>
        </Modal.Body>
      </Modal>
        </>
    )
}


export default MegaHeader;