import wlogo from "../res/images/wlogo.png";
import Home from "../res/images/Home.png";
import Maindashboardbg from "../res/images/Maindashboardbg.png";
import "./Maindashboardstyle.css";
import config from "../config";
import googleicon from '../res/images/googleicon.png'
import facebookicon from '../res/images/facebookicon.png'
import React, { Component } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { Col, Row } from 'antd';


class Predictiveanalytics extends Component {
  
  Data = [];
  userDataDTO = {
    month: 1,
    targetRevenue: 3,
  };
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }
 
 

  handleClick = (e) => {
    
    console.log(this.userDataDTO);
    axios
      .post("https://model.demo.data-pilot.com/", this.userDataDTO)
      .then((res) => {
        console.log(res.data);

        if (res.status === 200) {
          this.Data = res.data;
          this.setState({ data: res.data });
          console.log(this.state.data);
          console.log(this.Data["Facebook Revenue"]);
         
        } else {
          throw new Error(res.this.userDataDTO);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  render(props) {
   
    return (
      
      <>

            <Row style={{marginTop:"8vw", marginLeft:"5vw"}}>
              <Col span={24}>
                <p style={{textAlign:"left", fontSize:"20px", fontWeight:"normal", color:"black"}}>Enter Target Revenue</p>
              </Col>
              </Row>

                
              <Row style={{marginLeft:"5vw"}}>
              <Col span={8} style={{borderRight:"1px solid grey", paddingBottom:"5vw"}}>
              
              <div style={{display:"flex"}}>

              <div style={{marginTop:"2vw" }}>
              <label>
                    <h4>{"Target Revenue"} </h4>
                  </label>
                  <br></br>
                  <input
                    color="black"
                    className="input"
                    placeholder="Value"
                    style={{borderColor:"#0172CB"}}
                    type="number"
                    onChange={(e) => {
                      this.userDataDTO.targetRevenue = parseInt(e.target.value);
                    }}
                  />
              </div>


                  <div style={{marginTop:"2vw", marginLeft:"1vw"}}>
                  <label>
                    <h4>{"Month"} </h4>
                  </label>
                  <select
                    id="month"
                    style={{borderColor:"#0172CB"}}
                    name="month"
                    className="form-control input"
                    onChange={(e) => {
                      this.userDataDTO.month = parseInt(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  </div>

              </div>

                  <div>
                  <button
                    className="form-control forecast"
                    type="submit"
                    text="Forecast"
                    onClick={this.handleClick}
                   
                  >
                    Forecast
                  </button>
                  </div>
              </Col>
              <Col span={16}>
              <table style={{ width: '96%', margin: 'auto', textAlign: 'center' ,alignItems:"center", paddingLeft:"3vw"}}>
  <thead>
    <tr style={{textAlign:"center", alignItems:"center"}}>
      <th></th>
      <th><p style={{fontWeight:"normal" , fontSize:"17px" , textAlign:"center"}}>Spend Recommendation</p></th>
      <th><p style={{fontWeight:"normal" , fontSize:"17px", textAlign:"center"}}>
      Forecasted Revenue - Channel Wise
        </p></th>
    </tr>
  </thead>
  <tbody>
    <tr style={{ borderBottom: '2pt solid #f5f5f5' , height:"5vw"}} >
      <td><img src={googleicon} alt="googleicon" /></td>
      <td className="td">{this.Data["g_Spend"]}</td>
      <td className="td">{this.Data["g_revenue"]}</td>
    </tr>
    <tr style={{ borderBottom: '2pt solid #f5f5f5' ,height:"5vw"}}>
      <td><img src={facebookicon} alt="facebookicon" /></td>
      <td className="td">{this.Data["fb_Spend"]}</td>
      <td className="td">{this.Data["FB_revenue"]}</td>
      
    </tr>
   
    <tr>
      <td></td>
      <td ><p className="td" style={{ marginBottom: '1' }}>{this.Data["total_spend"]}</p>
   
    <p>  Total Spend</p>
      </td>
      <td > <p className="td" style={{ marginBottom: '1' }}>{this.Data["organic_rev"]}</p>
      <p>Organic Revenue</p></td>
    </tr>
  </tbody>
</table>



              </Col>
            </Row>

<Row>
  
</Row>

 

     
      </>
    );
  }
}

export default Predictiveanalytics;
