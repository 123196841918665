import React from "react";
import Home from "../res/images/Home.png";

import config from "../config";
import wlogo from "../res/images/wlogo.png";

import Maindashboardbg from "../res/images/Maindashboardbg.png";
import "./Maindashboardstyle.css";

import { Row, Col } from "react-bootstrap";

export default function Maindashboard(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Maindashboardbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "1150px",
        }}
      >
        <Row className="mt-3  mt-4+" style={{ display: "flex"  }}>
          <Col lg={2} style={{ display: "flex", marginLeft: "15px" }}>
            <img
              src={Home}
              style={{ width: "70px", height: "45px", marginTop: "10px" , cursor:"pointer" }}
              alt="wlogo"
              onClick={() => props.history.push(config.routes.maindashboard)}
            />
           
          </Col>
          <Col lg={6}>
            <h2 
              style={{
                fontSize: "35px",
                color: "white",
                marginLeft: "200px",
                marginRight: "197px",
              }}
            >
              Credit Risk Assessment Through Machine Learning
            </h2>
          </Col>

          <Col lg={4}>
            {/* <img
              src={wlogo}
              style={{ width: "100px", height: "45px", marginTop: "20px" , float:"right", marginRight:"10px" }}
              alt="wlogo"
            /> */}
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col lg={12} style={{ marginTop: "70px" }}>
            <iframe
              src="https://dp-cra.streamlit.app/?embedded=true"
              width="1000"
              height="800"
              frameBorder="0"
              scrolling="no"
              allowfullscreen
              title="Garment Defect Detection AI - model vs actual.mp4"
            ></iframe>
          </Col>
        </Row>
      </div>
    </>
  );
}
