import React, { useState } from "react";
import "./LinkedIn.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import pic1 from "../../res/images/pic1.png";
import pic2 from "../../res/images/pic2.png";
import pic3 from "../../res/images/pic3.png";
import { Modal, Button } from "react-bootstrap";
import worriedman from "../../res/images/worriedman.png";
import frame2 from "../../res/images/Frame 2.png";
import li from "../../res/images/linkedin headline.svg";
import woman from "../../res/images/woman.png";
import lilogo from "../../res/images/LinkedIn_logo 1.png";
import lilp from "../../res/images/lilp.png";
import head from "../../res/images/head.png";
import handshaking from "../../res/images/handshaking.png";
import arrowgraph from "../../res/images/arrowgraph.png";
import gcp from "../../res/images/gcp.png"
import looker from "../../res/images/looker.png"

const LinkedIn = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true); 
  return (
    <>
      <MegaHeader />

      <div className="row" style={{marginTop:"5vw"}}>
        <div className="col-12 ">
          <img className="w-100 " alt="man" src={li}></img>
        </div>
      </div>

      <div className="row px mt-7">
        <div className="col-6 d-flex align-items-center">
          <h1 className="li-text">
            Transforming<img src={lilogo} alt="logo"></img>
            Data into Powerful Marketing and Branding Insights.
          </h1>
        </div>
        <div className="col-6">
          <img src={woman} alt="woman" className="w-100"></img>
        </div>
      </div>

      <div className="row pr-10" style={{ marginTop: "7.5vw" }}>
        <div className="col-6 d-flex align-items-center">
          <img
            onClick={handleShow}
            src={lilp}
            // height={350}
            // width={350}
            alt="lp"
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <div className="col-lg-6 col-md-6" style={{marginTop:"9vw"}}>
          <p className="ls">
            A comprehensive Looker Studio
            <br />
            GA4 dashboard
          </p>
          <div className="d-flex">
            <img src={arrowgraph} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Identify top posts and campaigns to replicate successful
              strategies and avoid underperforming tactics. 
            </p>
          </div>

          <div className="d-flex">
            <img src={head} alt="81"></img>

            <p style={{ marginLeft: "1vw" }}>
              Get insights into user behavior by location and device to refine
              audience targeting, ensuring that campaigns and personal branding
              efforts reach the right people in the most effective way.
            </p>
          </div>

          <div className="d-flex">
            <img src={handshaking} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
              Have a clear picture of which campaigns and posts generate the
              highest number of Marketing Qualified Leads or professional
              connections, helping users focus on what works best.
            </p>
          </div>
          <div style={{paddingLeft:"6vw"}}>
            <img src={gcp} alt="gcp" height={76} width={108}></img>
            <img src={looker} alt="gcp" height={70} width={160}></img>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>LinkedIn Pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe
        title="d"
        style={{width:"100%", height:"700px", }}
        frameBorder="0"
        allowFullScreen="true"
        src="https://lookerstudio.google.com/embed/reporting/fd8e11db-75c5-4fa7-83f4-41acce57ebc0/page/DRLjD"
      />
        </Modal.Body>
      </Modal>

      {/* Steps involved */}
      <div className="row px" style={{marginTop:"10vw"}}>
        <div className="col-6" style={{lineHeight:0.8 , paddingRight:"6vw"}}>
          <p  className="industry-li" style={{float:"right"}}>Industry</p>
          <p className="app-li" style={{float:"right"}}>Applications</p>
        </div>

        <div className="col-2">
          <img src={pic1} alt="pic1"></img>
        </div>

        <div className="col-2">
          <img src={pic2} alt="pic1"></img>
        </div>

        <div className="col-2">
          <img src={pic3} alt="pic1"></img>
        </div>
      </div>

      <div className="row px mt-5">
        <div className="col-12" style={{lineHeight:0.8, marginBottom:"1.3vw"}}>
          <p className="cp">CROSS-PLATFORM INTEGRATION</p>
          <p className="cp-sub">
            Expanding insights to include data from other social media
            platforms, allowing for a more holistic view of digital marketing
            and personal branding performance.
          </p>
        </div>

        <div className="col-12" style={{lineHeight:0.8, marginBottom:"1.3vw"}}>
          <p className="cp">AUTOMATED OPTIMIZATION</p>
          <p className="cp-sub">
            Developing features that automatically suggest campaign and content
            adjustments to maximize conversions, lead quality, and professional
            connections.
          </p>
        </div>

        <div className="col-12" style={{lineHeight:0.8, marginBottom:"1.3vw"}}>
          <p className="cp">PREDICTIVE ANALYTICS</p>
          <p className="cp-sub">
          Utilizing insights to predict which LinkedIn strategies are likely to succeed based on historical data.
          </p>
        </div>
      </div>
    </>
  );
};

export default LinkedIn;
