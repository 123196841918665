import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../config";

export default function PublicRoute({
  component: Component,
  restricted,
  // user,
  ...rest
}) {
  // debugger;
  const user = JSON.parse(localStorage.getItem(config.keys.user));

  return (
    // Show the component only when the user is logged in
    // Otherwise, Redirect the user to /signin page

    <Route
      {...rest}
      exact
      render={(props) =>
        user && user.id && restricted ? (
          <Redirect to={config.routes.dashboard} />
        ) : (
          <Component {...props} user={user} />
        )
      }
    />
  );
}
