import React from "react";
import wlogo from "../res/images/wlogo.png";

import Maindashboardbg from "../res/images/Maindashboardbg.png";
import "./Maindashboardstyle.css";
import Home from "../res/images/Home.png";

import config from "../config";

import { Row, Col } from "react-bootstrap";

export default function Customersegmentation(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Maindashboardbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "1150px",

        }}
      >
        <Row className="mt-3  mt-4+" style={{ display: "flex" }}>
          <Col lg={2} style={{ display: "flex", marginLeft: "15px" }}>
            <img
              src={Home}
              style={{
                width: "70px",
                height: "45px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              alt="wlogo"
              onClick={() => props.history.push(config.routes.maindashboard)}
            />
          </Col>
          <Col lg={8}>
            <h2
              style={{
                fontSize: "35px",
                color: "white",
                marginLeft: "480px",
                marginRight: "400px",
              }}
            >
              Customer Segmentation
            </h2>
          </Col>

          <Col lg={4}>
            {/* <img
              src={wlogo}
              style={{ width: "100px", height: "45px", marginTop: "20px" }}
              alt="wlogo"
            /> */}
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Col>
            {/* <iframe
              width="1200px"
              height="700px"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F7uAXHhvG30uNKR23DFDNjp%2FData-Visualization%3Fnode-id%3D1752%253A18860%26scaling%3Dscale-down%26page-id%3D1752%253A18694%26starting-point-node-id%3D2253%253A22346"
              allowFullScreen
              title="CustomerSegmentation"
            ></iframe> */}
          </Col>
        </Row>
      </div>
    </>
  );
}
