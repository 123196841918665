import React from "react";
import "./Elm.css";
import MegaHeader from "../mega-header";
import PSW from "../../res/images/psw.png";
import Elmbg from "../../res/images/Elm.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import chess from "../../res/images/chess.png";
import sol from "../../res/images/solution.png";
import understandsection from "../../res/images/understandsection.png";
import mob from "../../res/images/mob.png";
import person from "../../res/images/person.jpeg";
import understand from "../../res/images/Understand.png";
import camera from "../../res/images/camera.png";
import ElmDashboard from "../../res/images/ElmDashboard.jpeg";
import train from "../../res/images/train.jpeg";
import bus from "../../res/images/bus.jpeg";
import nurse from "../../res/images/nurse.jpeg";
import ny from "../../res/images/ny.jpeg";
import angular from "../../res/images/Angularjs.png"
import fastapi from "../../res/images/fastapi.png"
import opencv from "../../res/images/opencv.png"
import RTSP from "../../res/images/RTSP.png"
import rectangle from "../../res/images/rectangle.jpeg";

const Elm = () => {
  return (
    <>
      <MegaHeader />
      <div className="strategy-containerElm">
        <div className="strategy-text">
          <h1 className="cvcc">
            Computer Vision & <br />
            Crowd Counting
          </h1>
          <p className="ElmSubheading">
            Helping authorities respond effectively to situations where public
            safety and security are at risk
          </p>
        </div>
      </div>
      <div className="row mt-7">
        <div className="col-lg-5">
          <img src={camera} alt=""></img>
        </div>
        <div className="col-lg-7">
          <img src={rectangle} alt="rectangle"></img>
          <h1>Problem</h1>
          <p className="elm-text">
            The client had a customer who wanted to use computer vision to
            monitor footfall at public places of interest. They wanted to
            monitor and analyze people's movements at events and public places
            for their safety, security, and welfare.
          </p>
          <p className="elm-text">
            The client wanted real-time analytics of a video feed from their
            installed cameras on a dashboard, with there being minimum delay
            between camera detection and dashboard visualization.
          </p>
        </div>
      </div>

      <div className="row mt-7 px">
        <div className="col-8">
          <img src={rectangle}></img>
          <h1 className="elm-solution">Solution</h1>

          <p className="normal-22">
            Data Pilot developed a user-friendly computer vision-based dashboard
            for tracking and analyzing foot traffic in various locations that
            can be accessed from any Internet connection. It featured
            customizable visualizations and real-time updates, helping the
            client monitor traffic patterns, identify peak hours, and make
            data-driven decisions to manage its staffing and inventory.
          </p>
        </div>

        <div className="col-4 center-person">
          <img src={person} alt="person"></img>
        </div>
      </div>

      <div className="row px mt-7">
        <iframe
          style={{ width: "1100px", height: "800px", marginTop: "7vw" }}
          frameBorder="0"
          allowFullScreen="true"
          src="https://app.powerbi.com/view?r=eyJrIjoiOTE5NDM2NDgtZTNlNS00YmE0LWJkNWYtMjViMDkwNTg4MTA4IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9"
          title="VideoToText"
        />
      </div>
      {/* Steps involved */}
      <div className="row px mt-7">
        <p className="mb-2 step-involved">Steps involved</p>
        <div className="col-4">
          <div className="card card-height">
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <button className="step-number">1</button>
                </div>
                <h5 className="card-title px-3 mb-0">Google Cloud Platform</h5>
              </div>
              <p className="card-text">
                Feeding the video transmission from the camera on ground to Google Cloud Platform (GCP)
              </p>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="card card-height">
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <div>
                  <button className="step-number">2</button>
                </div>
                <h5 className="card-title px-3 mb-0">Processing the feed to detect KPI’s</h5>
              </div>
              <p className="card-text">
                Optimizing packet transmission so that the new traffic doesn’t get jumbled with the old traffic.
              </p>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="card card-height">
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <div>    <button className="step-number">3</button></div>
                <h5 className="card-title px-3 mb-0">Displaying relevant KPI’s on a dashboard</h5>
              </div>
              <p className="card-text">
                Features of the dashboard in:<br />
                <ul>
                  <li>Real-time data visualization per camera and aggregate count from all cameras.</li>
                  <li>Customizable reporting</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>



      <div className="row px mt-7 justify-content-between ">
        <img src={angular} style={{ height: "65px", width: "190px" }} alt="angular"></img>
        <img src={fastapi} style={{ height: "60px", width: "170px" }} alt="angular"></img>
        <img src={opencv} style={{ height: "60px", width: "180px" }} alt="angular"></img>
        <img src={RTSP} style={{ height: "60px", width: "170px" }} alt="angular"></img>
      </div>




      <div className="row px mt-7">
        <div className="col-7">
          <h1 className="industry">Industry</h1>
          <p className="application">Applications</p>
          <img src={train} alt="train" className="w-100"></img>
          <p className="mt-4 normal-22">
            <strong>Retail</strong>- analyzing customer behavior and
            responsiveness to certain products can help retailers optimize their
            in-store experience and increase sales.
          </p>
          <p className="normal-22">
            <strong>Transportation Hubs</strong> - can help transportation firms
            monitor foot traffic in airports, train stations, and bus terminals
            to ensure smooth passenger flow. It can provide insights on peak and
            off-peak hours of foot traffic, which can be used to optimize staff
            deployment and maintenance schedules.
          </p>
          <p className="normal-22">
            <strong>Healthcare</strong> - can track patient movements in medical
            centers to reduce wait times and optimize the use of medical
            resources. During emergencies, like infectious disease outbreaks,
            the solution can help hospitals develop and refine evacuation plans
            based on real-time and historical foot traffic data to ensure safe
            and efficient evacuations.
          </p>
        </div>

        <div className="col-5">
          <img src={ny} alt="ny" className="py-2"></img>
          <img src={nurse} alt="nurse" className="py-2"></img>
          <img src={bus} alt="bus" className="py-2"></img>
        </div>
      </div>
    </>
  );
};

export default Elm;
