import React, { useState } from "react";
import "./DpStackOverFlow.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import group123 from "../../res/images/Group 123.png";
import group122 from "../../res/images/Group 122.png";
import group121 from "../../res/images/Group 121.png";
import laptop from "../../res/images/ZenBook Duo 14.png";
import { Modal, Button } from "react-bootstrap";
import p1 from "../../res/images/chart-user.png";
import p2 from "../../res/images/lightbulb-on.png";
import p3 from "../../res/images/users-medical.png";
import p4 from "../../res/images/Rectangle 48.png";
import p5 from "../../res/images/Rectangle 47.png";
import p6 from "../../res/images/Rectangle 46.png";

const DpStackOverFlow = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />
      <div className="strategy-container-dp-stack">
        <div className="strategy-text-ft">
          <p className="dp-unveils ml-4">Data Pilot Unveils DP Intelligence</p>
          <p className="Revolutionizing ml-4">Revolutionizing</p>
          <p className="Corporate ">Corporate</p>
          <p className="k-sharing ml-4">Knowledge Sharing</p>
        </div>
      </div>

      <div className="row px f-section">
        <div className="col-6">
          <p className="today">In Today’s</p>
          <p className="fast-paced">Fast-paced</p>
          <p className="business-world">Business World</p>
        </div>
        <div className="col-6 text-end">
          <p>companies are struggle with</p>
          <div>
          <button class="tag-button mx-1 my-1">information silos</button>
          <button class="tag-button mx-1 my-1">untapped employee expertise</button>
          <button class="tag-button mx-1 my-1">duplicated efforts</button>
          <button class="tag-button mx-1 my-1">inefficient problem-solving</button>

          </div>
          <p>
            The absence of a centralized, intelligent collaboration platform is
            holding back businesses from leveraging their most valuable asset -
            their combined knowledge and experience.
          </p>
        </div>
      </div>

      <div className="row  iot-based">
        <div className="col-lg-6 col-md-6 dpp-container">
          <p className="dpp">Data Pilot presents</p>
          <p>
            Data Pilot presents DP Intelligence (DPI) - a groundbreaking
            platform designed to transform how companies collaborate and
            innovate. Born from our own internal needs and perfected through
            rigorous testing, DPI is now ready to empower businesses of all
            sizes: 
          </p>
          <div className="d-flex">
            <img src={group121} alt="80"></img>

            <p style={{ marginLeft: "1vw" }} className="f-14">
              <strong className="ssc">Semantic Search Engine:</strong> Cutting-edge AI that understands context,
              delivering precise answers to complex queries in seconds.
            </p>
          </div>

          <div className="d-flex">
            <img src={group122} alt="80"></img>

            <p style={{ marginLeft: "1vw" }} className="f-14">
              <strong className="ssc">Collaborative Environment:</strong> A secure, intuitive space for employees
              to share insights, contribute knowledge, and learn from each other
              across departments.
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={group123} alt="80"></img>

            <p style={{ marginLeft: "1vw" }} className="f-14">
              <strong className="ssc">Streamlined Problem-Solving:</strong> An ecosystem where collective wisdom
              drives rapid solution development and continuous improvement.
            </p>
          </div>
        </div>

        <div className="col-6 d-flex align-items-center">
          <img
            onClick={handleShow}
            src={laptop}
            alt="lp"
            style={{ cursor: "pointer" , width:"100%" }}
          ></img>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>DataPilot Intelligence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            style={{ width: "100%", height: "700px" }}
            frameBorder="0"
            allowFullScreen="true"
            src="https://dp-intelligence.streamlit.app/?embedded=true"
            title="VideoToText"
          />
        </Modal.Body>
      </Modal>

      <div className="row mt-7 px " style={{ marginBottom: "7vw" }}>
        <div>
          <p className="dashboard-empowered">Impact Created</p>
        </div>

        <div className="col-4">
          <div className="card-style px-3 py-3">
            <img
              src={p1}
              style={{ height: "auto", width: "auto" }}
              alt="email icon"
              className="card-icon mb-1"
            />
            <p className="card-title-hubspot">Boosted Productivity</p>
            <p className="card-percentage">
              <strong>66.7%</strong>
            </p>
            <p className="card-description">
              Boosted click-through rates by 66.7% by identifying most engaging
              content 
            </p>
          </div>
        </div>

        <div className="col-4">
          <div className="card-style px-3 py-3">
            <img src={p2} alt="email icon" className="card-icon mb-1" />
            <p className="card-title-hubspot">Enhanced Innovation</p>
            <p className="card-percentage">
              <strong>27%</strong>
            </p>
            <p className="card-description">
              Reduced unsubscribe rates by 27% via better audience segmentation 
            </p>
          </div>
        </div>

        <div className="col-4">
          <div className="card-style px-3 py-3">
            <img src={p3} alt="email icon" className="card-icon mb-1" />
            <p className="card-title-hubspot">Sent Emails</p>
            <p className="card-percentage">
              <strong>95.9%</strong>
            </p>
            <p className="card-description">
              Improved overall deliverability to 95.9% of sent emails 
            </p>
          </div>
        </div>
      </div>

      {/* Steps involved */}
      <div className="row  mt-7  " style={{ marginBottom: "5vw" , marginLeft:"7vw" }}>
      <p className="fi-stack">
            Future Industry Uses/
            <br />
            Applications
          </p>
        <div className="col-6">
         

          <div>
            <p className="sc-sub">
              <strong> AI-Driven Insights:</strong>We're developing predictive
              analytics to suggest solutions based on historical data and
              emerging patterns.
            </p>
          </div>

          <div>
            <p className="sc-sub">
              <strong>Inter-Company Collaboration Networks:</strong>Future
              versions will facilitate secure knowledge sharing between partner
              organizations, creating expansive expertise ecosystems.
            </p>
          </div>

          <div>
            <p className="sc-sub">
              <strong>Adaptive Learning Pathways:</strong>Upcoming features will
              include personalized professional development recommendations
              based on individual interests and company needs.
            </p>
          </div>
        </div>
        <div className="col-6 d-flex align-items-center" style={{overflow:"hidden"}}>
          <img src={p4} alt="91" />
          <img className="px-2" src={p5} alt="91" />
          <img src={p6} alt="91" />
        </div>
      </div>
    </>
  );
};

export default DpStackOverFlow;
