import React from "react";
import wlogo from "../res/images/wlogo.png";
import config from "../config";
import { useHistory } from "react-router";
import Maindashboardbg from "../res/images/Maindashboardbg.png";
import "./Maindashboardstyle.css";
import Home from "../res/images/Home.png";

import { Row, Col } from "react-bootstrap";

export default function Maindashboard(props) {
  const history = useHistory();
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Maindashboardbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "1150px",
        }}
      >
        <Row className="mt-3 text-center align-items-center mt-4+">
        <Col lg={4} style={{display:"flex", marginLeft:"15px" }}>
              <img
                src={Home}
                style={{width: "70px", height: "45px", marginTop: "20px" ,cursor:"pointer" }}
                alt="wlogo"
                onClick={() =>
                  props.history.push(config.routes.maindashboard)}
              />
             
            </Col>
          <Col lg={8}>
            <div style={{ textAlign: "center" }}>
              {/* <img
                src={wlogo}
                style={{ width: "160px", height: "70px", marginTop: "5px" }}
                alt="wlogo"
              /> */}
            </div>
          </Col>

          <Col lg={12}>
            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: "35px", color: "white" }}>
                Robotic Process Automation
              </h2>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            marginTop: "130px",
            justifyContent: "center",
          }}
        >
          <Col lg={6}>
            <button
              onClick={() => history.push(config.routes.ocr)}
              type="button"
              className="rpaleftbtn "
            >
              Optical Character Recognition(OCR)
            </button>
          </Col>

          <Col lg={6} style={{ paddingLeft: "50px" }}>
            <button
              type="button"
              className="rparightbtn"
              onClick={() => history.push(config.routes.videototext)}
            >
              Video To <br></br>Text
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
}
