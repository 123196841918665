import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as yup from "yup";
import { getCurrentUser, signin } from "../services/auth";
import config from "../config";
import axios from "../config/axios";
import { Box, CircularProgress, Paper } from "@material-ui/core";

import bg7 from "../res/images/bg7.jpeg";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import useGlobalStyles from "../globalStyles/globalStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative", // Added to position spinner
  },
  pageBackground: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${bg7})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinnerOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2, // Ensure it overlays on top of form
  },
}));

let schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export default function SignIn(props) {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Set to false initially
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [email, password]);

  const userSignin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start spinner when the user submits
    schema
      .validate({ email, password })
      .then(async () => {
        try {
          setError("");
          const res = await signin({ email, password });
          const token = res.data.token;
          localStorage.setItem(config.keys.token, token);
          axios.setHeaders(token);
          const newRes = await getCurrentUser();
          localStorage.setItem(
            config.keys.user,
            JSON.stringify(newRes.data.user)
          );
          setTimeout(() => {
            props.history.push(config.routes.maindashboard);
          }, 4000); // Simulate a delay or wait for the process to complete
        } catch (ex) {
          setError(ex.response?.data?.message || "Login failed");
        } finally {
          setIsLoading(false); // Stop spinner after login completes
        }
      })
      .catch((err) => {
        setIsLoading(false); // Stop spinner if validation fails
        setError(err.message);
      });
  };

  return (
    <div className={classes.pageBackground}>
      <Box height="100%">
        <Box style={{ marginTop: "15vh" }}>
          <Container component="main" maxWidth="xs">
            <Paper className={globalStyles.paper} style={{ position: "relative" }}>
              <CssBaseline />
              <div className={classes.paper} style={{ paddingTop: "2vw" }}>
                <form className={classes.form} noValidate onSubmit={userSignin}>
                  {error && <Alert severity="error">{error}</Alert>}
                  <TextField
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{ style: { color: "white" } }}
                    style={{
                      borderRadius: 10,
                      padding: "5px 5px",
                      fontSize: "12px",
                      color: "white",
                    }}
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => {
                      setError("");
                      setEmail(e.currentTarget.value);
                    }}
                  />
                  <TextField
                    InputLabelProps={{ style: { color: "white" } }}
                    InputProps={{ style: { color: "white" } }}
                    style={{
                      borderRadius: 10,
                      padding: "5px 5px",
                      fontSize: "12px",
                      color: "white",
                    }}
                    margin="normal"
                    required
                    size="small"
                    fullWidth
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                      setError("");
                      setPassword(e.currentTarget.value);
                    }}
                  />
                  <FormGroup style={{ fontFamily: "Roboto", color: "white" }}>
                    <FormControlLabel
                      control={<Checkbox color="default" />}
                      label="Remember me"
                    />
                  </FormGroup>
                  <Button
                    style={{
                      borderRadius: 10,
                      backgroundColor: "#059baa",
                      padding: "10px 10px",
                      color: "white",
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "Roboto",
                      width: "200px",
                      height: "40px",
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    disabled={isLoading} // Disable the button during loading
                  >
                    LogIn
                  </Button>
                </form>
                {isLoading && (
                  <div className={classes.spinnerOverlay}>
                    <CircularProgress style={{ color: "rgb(5, 155, 170)" }} />
                  </div>
                )}
              </div>
            </Paper>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
