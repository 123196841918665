import React, { useState } from "react";
import { useHistory } from "react-router";
import PswMain from "../res/images/PswMain.png";
import jeansgroup from "../res/images/Jeans Group.png";
import websiteAnalytics from "../res/images/websiteAnalytics.png";
import routeOptimization from "../res/images/Route Optimization.png";
import IOT from "../res/images/IOT.png";
import MegaHeader from "./mega-header";
import config from "../config";
import GenAi from "../res/images/GenAiBg.png";
import Ocrbg from "../res/images/Ocrbg.png";
import Elmbg from "../res/images/Elmbg.png";
import group58 from "../res/images/Group 58.png";
import ftlp from "../res/images/ftlp.jpg";
import pswbg from "../res/images/PswMain.png"
import retailstore from '../res/images/retailstore.jpg'
import marketinganalytics from '../res/images/emailmarketing.jpg';
import lianalytics from '../res/images/lianalytics.jpg'
import oli from '../res/images/limain.png'
import r1 from '../res/images/r3.png'
import r2 from '../res/images/r2.jpg'
import mktg from '../res/images/mganalytics.png'
import group120 from "../res/images/dpstack.png"
import organicmedia from "../res/images/organicmedia.png"
import churnmain from "../res/images/churnmain.png"

const SliderComponent = () => {

 


  const history = useHistory();
  return (
    <>
      <MegaHeader />

      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{height:"60vh"}}
      >
        <div className="carousel-inner">
          {/* fleet tracking  */}

          <div
            className="carousel-item active"
            style={{ height: "60vh", overflow: "hidden" }}
          >
            <img className="d-block w-100" src={ftlp} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Safe Routes, Smarter Operations</h5>
                  <p>
                  Harnessing IoT for Uninterrupted Fleet Connectivity
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.FTRoute);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


         {/* lulusar  */}
        <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }}
          >
            <img className="d-block w-100" src={r1} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Data Leading the Way in Retail</h5>
                  <p>
                  Turning insights into retail wins 
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.Lulusar);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
         
          {/* Gen Ai  */}
          <div
           className="carousel-item  "
           style={{ height: "60vh", overflow: "hidden" }}
          >
            <img className="d-block w-100" src={GenAi} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Achieving Organizational Productivity with Gen AI </h5>
                  <p>
                    Developing an AI chatbot to help reduce operational costs by
                    20%
                  </p>
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.GenAi);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* OCR  */}

          {/* <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }}
          >
            <img className="d-block w-100" src={Ocrbg} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>
                  Quick Pass, Skip the Hassle 
                  </h5>
                  <p>
                  Slash boarding times by up to 70% with fast-track automation
                  </p>
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.OcrCaseStudy);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div> */}


          



{/* levis  */}
          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }}
          >
            <img className="d-block w-100" src={jeansgroup} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Fast, Accurate Textile Defect Detection </h5>
                  <p>
                  Automating Quality Control for Quicker, More Precise Results in Textile Manufacturing 
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.Levis);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* Hubspot  */}

          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={marketinganalytics} alt="Second slide" />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5 >Emails that Convert</h5>
                  <p>
                  Boost email performance with data-driven insights
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.Hubspot);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* LinkedIN  */}
          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={oli} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>LinkedIn Metrics Matter </h5>
                  <p>
                  Visualize, Analyze, and Boost Your LinkedIn Marketing and Branding
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.LinkedInRoute);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* marketing analytics  */}

          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={mktg} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Data Dive to Digital Thrive </h5>
                  <p>
                  Transforming a data-overwhelmed website into a high-performing digital asset, boosting engagement and conversions globally
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.MarketingAnalytics);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* DP stackover flow  */}
          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={group120} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Advancing Enterprise Collaboration with DP Intelligence</h5>
                  <p>
                  Breaking down information silos to unlock your company's collective potential 
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.DataPilotStackOverFlow);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* paid organic analytics  */}

          <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={organicmedia} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>Fueling Smarter Marketing with Integrated Media Insights</h5>
                  <p>
                  Unleashing the Power of Media Analytics for Smarter Business Decisions 
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.PaidOrganicMedia);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>



            {/* churn prediction analytics  */}

            <div
            className="carousel-item"
            style={{ height: "60vh", overflow: "hidden" }} 
          >
            <img className="d-block w-100" src={churnmain} alt="Second slide" style={{height:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <h5>AI-Driven Customer Retention: Stay Ahead of Churn </h5>
                  <p>
                  Predict, Engage, and Retain: A Smarter Approach to Reducing Churn 
                  </p>
                </div>
                <div className="text-right">
                  <button 
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(config.routes.Churn);
                    }}
                    data-bs-slide="false"
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>

       


        

        {/* Carousel controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
          style={{ width: "8%" }}
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
          style={{ width: "8%" }}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="row mt-4 px-5" style={{height:"30vh"}}>
      <div className="col"
     onClick={(event) => {
        event.stopPropagation();
        history.push(config.routes.OcrCaseStudy);
     }}>
    <div className="image-container">
        <img src={group58} 
             style={{borderRadius:"20px", height:"100%", width:"100%", objectFit:"cover"}} 
             className="card-img-top" 
             alt="..." />
        <div className="image-text">Optical Character Recognition</div>
    </div>
</div>

        <div className="col"
     onClick={(event) => {
        event.stopPropagation();
        history.push(config.routes.Elm);
     }}>
    <div className="image-container">
        <img src={Elmbg} 
             style={{borderRadius:"20px", height:"100%", width:"100%", objectFit:"cover"}} 
             className="card-img-top" 
             alt="..." />
        <div className="image-text">Vigilant Vision</div>
    </div>
</div>


        <div className="col"
         onClick={(event) => {
          event.stopPropagation();
          history.push(config.routes.PSW);
        }}>
          <div className="image-container">
            <img src={pswbg}   style={{borderRadius:"20px", height:"100%", width:"100%", objectFit:"cover"}}  className="card-img-top"  alt="..." />
            <div className="image-text">Trade Empowerment</div>
          </div>
        </div>
      </div>



   
    </>
  );
};

export default SliderComponent;
