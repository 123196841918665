import React from "react";
import Dialog from "../common/Dialog";
import { useHistory } from "react-router";
import config from "../../config";
import axiosObj from "../../config/axios";

export default function SessionValidator({ isSessionExpired }) {
  const history = useHistory();
  const redirectToLogin = () => {
    axiosObj.resetHeaders();
    localStorage.removeItem(config.keys.user);
    localStorage.removeItem(config.keys.token);
    if (history && history.push) {
      history.push(config.routes.login);
    } else {
      window.location = config.routes.login;
    }
  };
  return (
    <div>
      <Dialog
        title="Session Expired!"
        description="Your session has been expired please signin again."
        open={isSessionExpired}
        buttons={[
          {
            onClick: redirectToLogin,
            color: "primary",
            label: "Signin again",
          },
        ]}
      />
    </div>
  );
}
