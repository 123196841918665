import React from "react";

import Tabs from "@material-ui/core//Tabs";
import Tab from "@material-ui/core//Tab";
import Box from "@material-ui/core//Box";
import { Tableau } from "../components/tableau";
import { AppBar, Typography } from "@material-ui/core/";
import AppContainer from "../components/core/AppContainer";

import { Buffer } from "buffer";

global.Buffer = Buffer;

const dashboardIds = [

 
  "49f7686f-1e03-4g8f-b82e-de3bf697fcf6",
  "49f7666f-1e03-4e8f-b82e-ce3bf697fce4",
  "49f7666f-1f03-4e8f-b82e-ce3bf697fce4",
  "49f7666f-1e03-4e8f-b82e-ce3bf667fce4",
  "49f7686f-1e03-4g8f-b82e-ce3bf697fcf3",
  "49f7686f-1e03-4g8f-b82e-ce3bf697fcf6",
  "ed1a83c0-c98c-43a5-8248-19d6u99effd4",
  "49f7636f-1e03-4g8f-b82e-ve3bf647fcf3",
  "49f7686f-1e03-4e8f-b82e-ce3bf697fce4",
  "ed1a83c0-c98c-43a5-8128-19d5c99effd4",
  





  
];
const dashboardId = [
  6,5,9,7,8,4
];

export default function B2BMarketingAnalytics(props) {
  console.log(
    "🚀 ~ file: Dashboard.jsx ~ line 56 ~ Dashboard ~  props.user",
    props.user.dashboards
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Nav = (
    <Box>
      <Box sx={{ bgcolor: "primary" }}>
        <AppBar position="static"></AppBar>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          // allowScrollButtons
          aria-label="scrollable force tabs example"
        >
          {props?.user?.dashboards
            ?.filter((item) => dashboardIds.includes(item.dashboardId))
            .map((d, i) => {
              const dashboard = props?.user?.isGuest ? d : d?.dashboard;

              return <Tab label={dashboard?.label} key={i} value={i} />;
            })}
        </Tabs>
        {props?.user?.dashboards
          ?.filter((item) => dashboardIds.includes(item.dashboardId))
          .map((d, i) => {
            const dashboard = props?.user?.isGuest ? d : d?.dashboard;
            console.log({ dashboard });
            const type = dashboard?.type;

            if (type === "tableau") {
              return (
                <TabPanel key={i} index={i} value={value} {...a11yProps(i)}>
                  <Box>
                    <Tableau url={dashboard?.url} />
                  </Box>
                  {/* </Box> */}
                </TabPanel>
              );
            }
            if (type === "metabase") {
            
              var jwt = require("jsonwebtoken");
              var METABASE_SITE_URL = "https://metabase.demo.data-pilot.com";
              var METABASE_SECRET_KEY =
                "0a4c8ae803f58aa527ade3436911d850c9d30ba48e21e614c73bc606584d1d4c";
              var payload = {
                // {6,59,7,8,4} 
                resource: {dashboard: dashboardId[i] },
                
                params: {},
                exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
              };
             
              console.log(payload.resource)
              var token = jwt.sign(payload, METABASE_SECRET_KEY);
              var url =
                METABASE_SITE_URL +
                "/embed/dashboard/" +
                token +
                "#theme=light&bordered=true&titled=true";
                console.log(url)

              // var payload = {
              //   resource: {
              //     dashboard: config.metabasedashboard[dashboard.name],
              //   },
              //   params: {},
              //   exp: Math.round(Date.now() / 1000) + 60 * 60, // 60 minute expiration
              // };

              // var token = jwt.sign(payload, process.env.REACT_APP_METABASE_KEY);
              // var url = dashboard?.url + token + "#bordered=true&titled=true";
              // console.log(url);
              return (
                <TabPanel key={i} index={i} value={value} {...a11yProps(i)}>
                  {/* <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                > */}
                  <Box>
                    <iframe
                      style={{ width: "100%", height: "3000px" }}
                      allowFullScreen={true}
                      src={url}
                      title={dashboard.label}
                    />
                  </Box>
                  {/* </Box> */}
                </TabPanel>
              );
            }
            if (type === "metabasepublic") {
              console.log(dashboard.url);
              return (
                <TabPanel key={i} index={i} value={value} {...a11yProps(i)}>
                  {/* <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"center"}
                > */}
                  <Box>
                    <iframe
                      id="metabase"
                      style={{ width: "100%", height: "4000px" }}
                      allowFullScreen={true}
                      src={dashboard.url}
                      title={dashboard.label}
                      allowtransparency
                    />
                  </Box>
                  {/* </Box> */}
                </TabPanel>
              );
            }
            if (type === "powerbi") {
              let dashboardUrl = dashboard?.url;
              var Label = dashboard?.label;

              return (
                <TabPanel key={i} index={i} value={value} {...a11yProps(i)}>
                  <Box
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"center"}
                  />
                  <div>
                    {(() => {
                      if (Label === "Sales Analysis") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Analysis of Sales & Revenue from a marketing
                            perspective
                          </h3>
                        );
                      }
                      if (Label === "Organic Campaign Analysis") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Campaign level analysis of organic brand health
                            across owned media channels
                          </h3>
                        );
                      }

                      if (Label === "Paid Media Analysis") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Analysis of marketing spend and its impact across
                            the consumer disposition funnel – from awareness to
                            conversion
                          </h3>
                        );
                      }
                      if (Label === "Marketing Spend Analytics") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Deep dive into marketing spend from a time-series
                            and campaign mechanics perspective
                          </h3>
                        );
                      }
                      if (Label === "Organic Media Analytics") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Analysis of organic brand health in terms of
                            audience engagement, talkability and sentiment
                          </h3>
                        );
                      }
                      if (Label === "Sales And Product Analytics") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Deep dive into Sales & Revenue from a commercial
                            perspective
                          </h3>
                        );
                      }
                      if (Label === "Customer Analytics") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Web and Product Analytics giving a view of customer
                            behaviour
                          </h3>
                        );
                      }
                      if (
                        Label === "Inventory Visibility - Inventory visibility"
                      ) {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Quantitative analysis of inventory on-hand by date,
                            location, category and status
                          </h3>
                        );
                      }
                      if (
                        Label ===
                        "Product Sales Analytics - Product Sales Analytics"
                      ) {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Comprehensive overview of sales across categories
                            with sales force management perspective
                          </h3>
                        );
                      }
                      if (Label === "Stock Management - Stock Management") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Supply and demand inquiry of stock based on order
                            status and units fit for sale
                          </h3>
                        );
                      }
                      if (
                        Label ===
                        "Costs and Sales Analysis - Cost Vs Sales Analytics"
                      ) {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Deep dive into inventory costs and sales
                            contributing to total profits with an inquiry of
                            payables and receivables
                          </h3>
                        );
                      }
                      if (Label === "Supply Chain - Product Sales Analytics") {
                        return (
                          <h3 style={{ textAlign: "center" }}>
                            Investigation of inventory supply chain by tracking
                            supply and demand side for each product
                          </h3>
                        );
                      }
                    })()}
                  </div>
                  <Box>
                    <iframe
                      style={{ width: "100%", height: "2000px" }}
                      frameBorder="0"
                      allowFullScreen={true}
                      src={dashboardUrl}
                      title="PI"
                    />
                  </Box>
                  {/* </Box> */}
                </TabPanel>
              );
            } else {
              return (
                <TabPanel key={i} index={i} value={value} {...a11yProps(i)}>
                  <Typography>Dashboard comming soon!</Typography>
                </TabPanel>
              );
            }
          })}
      </Box>
    </Box>
  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ overflowX: "hidden" }}
      >
        {value === index && (
          <Box sx={{ p: 3 }} width="100%">
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <AppContainer title={""} user={props.user}>
      <div>{Nav}</div>
    </AppContainer>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
