import React, { useState } from "react";
import "./OrganicPaidAnalytics.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import rectangle from "../../res/images/Rectangle 34.png";
import bordertop from "../../res/images/borderfull.png";
import group72 from "../../res/images/Group 72.png";
import group73 from "../../res/images/Group 73.png";
import group74 from "../../res/images/Group 74.png";
import group77 from "../../res/images/Group 76.png";
import group76 from "../../res/images/Group 77.png";
import group78 from "../../res/images/Group 78.png";
import group67 from "../../res/images/Group 67.png";
import group899 from "../../res/images/Group 988.png"
import SideBar from "../Drawer/SideBar";
import { Modal, Button } from "react-bootstrap";
import fullscreen from '../../res/images/fullscreen.png'

const Lulusar = () => {

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  return (
    <>
      <MegaHeader />

      <div className="row px mt-5">
        <img src={bordertop} alt="bordertop" className="mt-5 mb-3"></img>
        <div className="col-8">
          <p className="misguided">
            Fueling Smarter Marketing
          </p>
        </div>
        <div className="col-4">
          <p className="disparate" style={{ marginTop: "3vw !important" }}>
            Unleashing the Power of Media Analytics for Smarter Business Decisions
          </p>
          {/* <div
            className="px-2 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <img src={group72} className="iconhw" alt="g72"></img>
            <img src={group73} className="iconhw" alt="g73"></img>
            <img src={group74} className="iconhw" alt="g74"></img>
          </div> */}
        </div>
      </div>



      <div className="row px mt-5">
        <div className="col-8">
          <img src={rectangle} alt="rectangle"></img>
          {/* <p className="Retail mt-3 pl-30">Retail and e-commerce</p> */}
          <p className="f-20 pl-30" style={{marginTop:"3vw"}}>
            In today’s digital landscape, businesses across industries struggle to track and optimize their media performance. Disconnected insights from organic content and paid campaigns lead to missed opportunities, inefficient strategies, and ineffective decision-making.
          </p>
        </div>
      </div>

      <div className="row mt-7 px">
        <div className="d-flex">
          <img src={fullscreen} className="mb-2 fullscreen" alt="full-screen" height={20} width={20}
            onClick={handleShow}
          ></img>

        </div>
        <div className="col-12">
          <SideBar
          />
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Data Pilot Analytics Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SideBar />
        </Modal.Body>
      </Modal>


      {/* Steps involved */}
      {/* <div className="row px mt-7">
        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group77} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Developing a data extraction and transformation process where all
            the data for both retail and online sales can be found in one single
            source. The data was consolidated from various sources, such as the
            company’s website, social media, and Google Ads.
          </p>
        </div>

        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group76} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Consolidated data was stored in an AWS database, where it would be
            used to help analyze the effectiveness of each marketing and sales
            channel.
          </p>
        </div>

        <div className="col-4" style={{ display: "flex" }}>
          <img src={group78} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Custom dashboards to display retail and online sales data side by
            side. Each dashboard was designed to be clear and uncluttered, with
            no more than 4-5 widgets. The dashboards allowed users to view
            specific numbers and filter the data by time range, brand, and
            city.
          </p>
        </div>
      </div> */}


      <div className="row px mt-7">
        <div className="col-2">
          <img src={rectangle} alt="rect" className="w-100"></img>
          <p className="impact">Impact</p>
        </div>
      </div>

      <div className="row mt-7">
        <div
          className="col-7"
          style={{ paddingLeft: "10vw", paddingRight: "5vw" }}
        >
          <div>
            <p className="cheading">Comprehensive Analytics</p>
            <p className="f-26">
              Improve customer engagement and drive loyalty by analyzing organic feedback and sentiment
            </p>
          </div>

          <div>
            <p className="cheading">Strategic Planning</p>
            <p className="f-26">
              Maximize ad spend efficiency and increase conversion rates through data-driven decisions
            </p>
          </div>

          <div>
            <p className="cheading">Top-performing products</p>
            <p className="f-26">
              Tailor content strategies by understanding audience preferences and campaign performance
            </p>
          </div>

          <div>
            <p className="cheading">Optimized Spending</p>
            <p className="f-26">
              Refine targeting and improve lead quality with precise insights into paid media outcomes
            </p>
          </div>
        </div>

        <div className="col-5">
          <img src={group899} alt="g67" className="w-100"></img>
        </div>
      </div>
    </>
  );
};

export default Lulusar;
