import React from "react";
import wlogo from "../res/images/wlogo.png";
import Home from "../res/images/Home.png";
import Back from "../res/images/Back.png";
import config from "../config";
import Maindashboardbg from "../res/images/Maindashboardbg.png";
import "./Maindashboardstyle.css";

import { Row, Col } from "react-bootstrap";


export default function Maindashboard(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Maindashboardbg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "1150px",
        }}
      >
        <Row className="" style={{ display: "flex" }}>
        <Col lg={2} style={{display:"flex", marginLeft:"15px" }}>
              <img
                src={Home}
                style={{ width: "70px", height: "45px", marginTop: "20px" , cursor:"pointer" }}
                alt="wlogo"
                onClick={() =>
                  props.history.push(config.routes.maindashboard)}
              />
              <img
                src={Back}
                style={{ width: "55px", height: "40px", marginTop: "24px" , cursor:"pointer" }}
                alt="wlogo"
                onClick={() =>
                  props.history.push(config.routes.rpa)}
              />
            </Col>
          <Col lg={6} style={{alignItems:"center", justifyContent:"center"}}>
            <h2
              style={{
                fontSize: "35px",
                color: "white",
                marginLeft: "370px",
                marginRight: "370px",
              }}
            >
              Optical Charecter Recognition
            </h2>
          </Col>

          <Col lg={4}>
            {/* <img
              src={wlogo}
              style={{ width: "100px", height: "45px", marginTop: "20px" }}
              alt="wlogo"
            /> */}
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col lg={12} style={{ marginTop: "70px" }}>
            <iframe
              style={{ width: "1000px", height: "450px" }}
              frameBorder="0"
              allowFullScreen="true"
              src="https://shdatapilot-ocr-app-streamlit-tbef9h.streamlit.app/?embedded=true"
              title="Ocr"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
