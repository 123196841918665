import React, { useContext, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import InputLabel from "@material-ui/core/InputLabel";

// import FormControl from "@material-ui/core/FormControl";
import AppContainer from "../components/core/AppContainer";
import useGlobalStyles from "../globalStyles/globalStyles";
import {
  getAllUsers,
  createUser,
  deleteUser,
  getAllDashboards,
} from "../services/admin";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  TextField,
  Link,
} from "@material-ui/core";
import { AccountCircle, EmailOutlined, LockRounded } from "@material-ui/icons";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import config from "../config";
import SessionContext from "../context/SessionContext";
import Dialog from "../components/common/Dialog";
import { useHistory } from "react-router";
// import StoreIcon from "@material-ui/icons/Store";
// import { useCallback } from "react";
// import { Link } from "react-router-dom";

export default function Users(props) {
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  // const [isSessionExpired, setSessionExpired] = useContext(SessionContext);
  const session = useContext(SessionContext);
  const [page, setPage] = React.useState(0);

  const [selectedUserToDelete, setSelectedUserToDelete] = React.useState();
  const [removingUser, setRemovingUser] = React.useState(false);
  const [usersList, setUsersList] = React.useState([]);
  const [error, setError] = React.useState();
  const [message, setMessage] = React.useState();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // console.log(isSessionExpired);
  console.log(session);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const addNewUser = (user) => {
    setUsersList([user, ...usersList]);
  };

  useEffect(() => {
    getAllUsers()
      .then((res) => {
        if (res.data.users) {
          setUsersList(res.data.users);
        }
      })
      .catch((ex) => {
        if (ex.response && ex.response.status === 401) {
          session.setSessionExpired(true);
        } else {
          setError(ex.response?.data.message);
          resetMessages();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserDelete = (user) => {
    setSelectedUserToDelete(user);
  };

  const deleteSelectedUser = () => {
    setRemovingUser(true);
    deleteUser(selectedUserToDelete.id)
      .then((res) => {
        const updatedUSerList = usersList.filter(
          (u) => selectedUserToDelete.id !== u.id
        );
        setMessage(res.data.message);
        resetMessages();
        setUsersList(updatedUSerList);
        setRemovingUser(false);
        setSelectedUserToDelete("");
      })
      .catch((ex) => {
        setSelectedUserToDelete("");
        setRemovingUser(false);
        if (ex.response && ex.response.status === 401) {
          session.setSessionExpired(true);
        } else {
          setError(ex.response?.data.message);
          setSelectedUserToDelete("");
          resetMessages();
        }
      });
  };

  const resetMessages = () => {
    setTimeout(() => {
      setError("");
      setMessage("");
    }, 3000);
  };

  return (
    <AppContainer title="Manage Users" user={props.user}>
      <Dialog
        title="Delete user"
        description="Are you sure you want to delete this user?"
        onClose={(event, reason) => {
          if (
            !removingUser &&
            reason !== "backdropClick" &&
            reason !== "escapeKeyDown"
          ) {
            // Set 'open' to false, however you would do that with your particular code.
            // setOpen(false);
            setSelectedUserToDelete("");
          }
        }}
        open={selectedUserToDelete && selectedUserToDelete.id ? true : false}
        // dialogProps={{ disableBackdropClick: removingUser }}
        // handleClose={() => setSelectedUserToDelete("")}
        buttons={[
          {
            onClick: () => setSelectedUserToDelete(""),
            color: "default",
            label: "cancel",
          },
          {
            onClick: deleteSelectedUser,
            label: removingUser ? "Deleting..." : "Delete",
            disabled: Boolean(removingUser),
            className: globalClasses.deleteBtn,
          },
        ]}
      />
      <Container maxWidth="lg" className={globalClasses.container}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              className={globalClasses.cursorPointer}
              onClick={() => history.push(config.routes.dashboard)}
            >
              Dashboard
            </Link>
            <Link
              style={{ color: "#fff" }}
              className={globalClasses.cursorPointer}
              onClick={() => history.push(config.routes.users)}
              aria-current="page"
            >
              Manage user
            </Link>
          </Breadcrumbs>
        </Box>

        <Grid container spacing={3}>
          <Grid item={true} md={12} lg={12}>
            <AddUser addNewUser={addNewUser} />
          </Grid>
          <Grid item={true} md={12} lg={12} style={{ width: "100%" }}>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            <br />
            <Paper >
              <TableContainer>
                <Table
                  // stickyHeader
                  aria-label="sticky table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width={120}>
                        <b>Username</b>
                      </TableCell>
                      <TableCell width={120}>
                        <b>Email</b>
                      </TableCell>

                      <TableCell width={120}>
                        <b>Dashboards</b>
                      </TableCell>
                      <TableCell width={120}>
                        <b>Created at</b>
                      </TableCell>
                      <TableCell width={120}>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersList.length > 0 &&
                      usersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              <TableCell width={120}>{row.username}</TableCell>
                              <TableCell width={120}>{row.email}</TableCell>

                              <TableCell width={120}>
                                {row?.dashboards
                                  ?.map(function (d) {
                                    const dash = d.dashboard;
                                    return dash?.label;
                                  })
                                  .join(", ")}
                              </TableCell>
                              <TableCell width={120}>
                                {new Date(row.createdAt).toLocaleString()}{" "}
                              </TableCell>
                              <TableCell width={120}>
                                <Button
                                  className={globalClasses.deleteBtnContained}
                                  onClick={() => handleUserDelete(row)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                align="center"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppContainer>
  );
}

let schema = yup.object().shape({
  username: yup.string().required(),

  userType: yup.string().notRequired(),

  email: yup.string().email().required(),
  password: yup.string().required(),
  dashboards: yup
    .array()
    .min(1)
    .required("You have to select at least one dashboard for user"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
function AddUser(props) {
  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [dashboardList, setDashboardList] = useState([]);
  // const [storeCodes, setStoreCodes] = useState([]);

  const [dashboards, setDashboards] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [loadingStoreCodes, setLoadingStoreCode] = useState(true);

  const session = useContext(SessionContext);

  useEffect(() => {
    const fetchDashboards = () => {
      getAllDashboards()
        .then((res) => {
          if (res.data.dashboards) {
            setDashboardList(res.data.dashboards);
          }
        })
        .catch((ex) => {
          setError(ex.response?.data.message);
          resetMessages();
        });
    };

    fetchDashboards();
  }, []);

  const resetMessages = () => {
    setTimeout(() => {
      setError("");
      setMessage("");
    }, 3000);
  };

  const handleChange = (e) => {
    setError("");

    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    setNewUser({ ...newUser, [name]: value });
  };

  const handleCheckboxChange = (e, id) => {
    console.log("e.target.checked", e.target.checked);
    if (e.target.checked) {
      setDashboards([...dashboards, id]);
    } else {
      const updatedDashboardList = dashboards.filter((el) => el !== id);
      console.log("updatedDashboardList", updatedDashboardList);
      setDashboards(updatedDashboardList);
    }
  };

  const handleSubmit = (e) => {
    setError("");
    e.preventDefault();
    const updatedUser = { ...newUser, dashboards };
    console.log(updatedUser);
    schema
      .validate(updatedUser)
      .then(async () => {
        addUser(updatedUser);
      })
      .catch(function (err) {
        // console.log(err);

        setError(err.errors[0]);
        resetMessages();
      });
  };

  const addUser = async (user) => {
    try {
      setLoading(true);
      const res = await createUser(user);
      props.addNewUser(res.data.user);
      setMessage(res.data.message);
      setNewUser({
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
      });
      // const temp = [...dashboardList];
      // setDashboardList([]);
      // setDashboards([]);
      // setDashboardList(temp);

      resetMessages();
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 401) {
        session.setSessionExpired(true);
      }
      setError(
        ex.response?.data.message ||
          "Something went wrong please contact your administrator"
      );
      resetMessages();
    }
  };

  return (
    <Paper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Add User</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            <br />
            <Grid container spacing={2}>
              <Grid item={true} md={6} lg={6} sm={12}>
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  variant="outlined"
                  size="small"
                  required
                  value={newUser.username}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item={true} md={6} lg={6} sm={12}>
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  value={newUser.email}
                  required
                  fullWidth
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item={true} md={6} lg={6} sm={12}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  required
                  value={newUser.password}
                  fullWidth
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item={true} md={6} lg={6} sm={12}>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm password"
                  type="password"
                  required
                  size="small"
                  variant="outlined"
                  value={newUser.confirmPassword}
                  fullWidth
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item={true} md={12} lg={12} sm={12}>
                {dashboardList &&
                  dashboardList?.map((el, i) => (
                    <FormControlLabel
                      key={el.id}
                      control={
                        <Checkbox
                          // checked={state.checkedA}
                          color="inherit"
                          onChange={(e) => handleCheckboxChange(e, el.id)}
                          name={el.label}
                        />
                      }
                      label={el.label}
                    />
                  ))}
              </Grid>
              <Grid item={true} md={12} lg={12} sm={12}>
                <Button
                  color="primary"
                  type="submit"
                  // size="small"
                  variant="contained"
                  disabled={Boolean(loading)}
                >
                  Save
                  {loading && (
                    <CircularProgress
                      size={16}
                      style={{ color: "#fff", marginLeft: 10 }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
