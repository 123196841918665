import config from "../config";
import axios from "../config/axios";

export const signin = (creds) => {
  console.log(config);
  return axios.post(config.apiEndPoint + "/auth/signin", creds);
};
export const storeGuestUserData = (data) => {
  console.log(config);
  return axios.post(config.apiEndPoint + "/auth/guest", data);
};
export const getCurrentUser = () => {
  axios.setAuthHeader();
  return axios.get(config.apiEndPoint + "/auth/currentUser");
};
export const logout = () => {
  axios.setAuthHeader();
  return axios.get(config.apiEndPoint + "/auth/logout");
};
