import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import wlogo from "../../res/images/wlogo.png";
import TabHome from "../../res/images/TabHome.png";


// import anlogo from "../../res/images/anlogo.gif";
// import SettingsIcon from "@material-ui/icons/Settings";
// import { Link } from "react-router-dom";

import { Avatar, CircularProgress, ListItemAvatar } from "@material-ui/core";
import config from "../../config";
import { logout } from "../../services/auth";
import { useHistory } from "react-router-dom";
import axiosObj from "../../config/axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  profileContainer: {
    // paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  largeAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  linkText: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
}));

export default function AppContainer({ children, title, user, props }) {
  const classes = useStyles();
  const history = useHistory();
  //   const [open, setOpen] = React.useState(true);

  const [loggingout, setLogggingout] = React.useState(false);
  //   const handleDrawerOpen = () => {
  //     setOpen(true);
  //   };
  //   const handleDrawerClose = () => {
  //     setOpen(false);
  //   };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const logoutUser = async () => {
    try {
      setLogggingout(true);
      if (!user?.isGuest) {
        await logout();
      }
      localStorage.removeItem(config.keys.user);
      localStorage.removeItem(config.keys.token);
      axiosObj.removeHeaders();
      window.location = config.routes.login;
    } catch (ex) {
      setLogggingout(false);
      console.log(ex);
    }
  };
  const [menuAnchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(menuAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let nameLetter = "";
  if (user?.username) {
    nameLetter = user?.username[0].toUpperCase();
  } else {
    nameLetter = user?.firstName[0]?.toUpperCase();
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        // className={clsx(classes.appBar, open && classes.appBarShift)}
        className={clsx(classes.appBar)}
      >
        <Toolbar
          className={classes.toolbar}
          style={{ backgroundColor: "#1a3347" }}
        >
          {/* <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
           
           
          >
            {title}
          </Typography> */}

          <div style={{ justifyContent: "left" ,display:"flex" }}>
            {/* <img
              src={wlogo}
              style={{
                width: "100px",
                height: "40px",
                alignItems: "left",
                float: "left",
                marginRight: "950px",
              }}
              alt="wlogo"
            /> */}
            
          </div>
          <div style={{ display: "flex", justifyContent: "center" , marginLeft: "auto" }}>
  <div style={{ marginLeft: "auto" }}>
    {/* Your image code here */}
    <img
      src={TabHome}
      style={{ width: "60px", height: "45px", cursor: "pointer" , marginTop:"10px" }}
      alt="wlogo"
      onClick={() => history.push(config.routes.maindashboard)}
    />
  </div>

  <IconButton
    aria-label="more"
    aria-controls="long-menu"
    aria-haspopup="true"
    color="inherit"
    onClick={handleClick}
  >
    {/* <MoreVertIcon /> */}
    <Avatar>{nameLetter}</Avatar>
  </IconButton>
</div>

          
          <Menu
            id="long-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {/* <Box p={2}> */}
            <MenuItem button={false}>
              <ListItemAvatar>
                <Avatar>{nameLetter}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={user?.username} secondary={user?.email} />
            </MenuItem>
            <Divider />

            <MenuItem button={false}>
              <List component="nav" style={{ width: "100%" }}>
                {user && user.userType === "admin" && (
                  <ListItem
                    dense
                    button
                    onClick={() => history.push(config.routes.users)}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage users" />
                  </ListItem>
                )}

                <ListItem
                  dense
                  button
                  className={classes.linkText}
                  onClick={logoutUser}
                >
                  <ListItemIcon>
                    {loggingout ? (
                      <CircularProgress size={20} color="textSecondary" />
                    ) : (
                      <ExitToAppIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
}
