import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Ocr.css";
import MegaHeader from "../mega-header";
import group51 from "../../res/images/Group 51.png";
import group50 from "../../res/images/Group 50.png";
import group55 from "../../res/images/Group 55.png";
import GenAi1 from "../../res/images/GenAi1.png";
import GenAi2 from "../../res/images/GenAi2.png";
import GenAi3 from "../../res/images/GenAi3.png";
import GenAi4 from "../../res/images/GenAi4.png";
import ocrbtnbg from "../../res/images/ocrbtnbg.png";
import fullscreen from "../../res/images/fullscreen.png"

const Ocr = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <MegaHeader />
      <div className="strategy-containerOcr">
        <div className="strategy-text-Ocr">
          <h1 className="Airline">AIRLINES</h1>
          <br />
          <img src={ocrbtnbg} alt="h"></img>
        </div>
      </div>

      <div className="row mt-7 px">
        <div className="d-flex">
          <img src={fullscreen} className="mb-2 fullscreen" alt="full-screen" height={20} width={20} 
          onClick={handleShow}
          ></img>
       
        </div>

        <div className="col-lg-12 col-md-12">
          <iframe
            style={{ height: "100vh" }}
            src="https://app-ocr-5fruz6mrkkfozf3wau24ay.streamlit.app/?embedded=true"
            height="100vh"
            width="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            title="Streamlit App"
          ></iframe>
        </div>
      </div>

      {/* Modal for Streamlit App */}
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Image to Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://app-ocr-5fruz6mrkkfozf3wau24ay.streamlit.app/?embedded=true"
            style={{
              height: "80vh",
              width: "100%",
              border: "0",
            }}
            frameBorder="0"
            scrolling="yes"
            allowFullScreen
            title="Streamlit App"
          ></iframe>
        </Modal.Body>
      </Modal>

      {/* Steps involved */}
      <div className="row px mt-7">
        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group55} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            AI-powered OCR technology that transforms boarding passes into
            instant digital data, streamlining airport check-in processes.
          </p>
        </div>

        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group50} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            A mobile application that serves as a smart assistant, providing
            staff with immediate access to passenger information across various
            airline formats.
          </p>
        </div>

        <div className="col-4" style={{ display: "flex" }}>
          <img src={group51} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Guide airport personnel through efficient data capture, reducing
            queues and enhancing passenger experience in high-traffic
            situations.
          </p>
        </div>
      </div>

      <div className="row px mt-7">
        <div className="col-7">
          <h1 className="industry">Future</h1>
          <p className="application">Applications</p>
          <img src={GenAi1} alt="train" className="w-100"></img>
          <p className="mt-4 normal-22">
            <strong>Revolutionizing the Passenger Journey</strong> - Imagine a
            world where long queues are a thing of the past. Our solution
            slashes wait times, transforming the dreaded check-in process into a
            swift experience. Passengers now glide through airports with a
            smile, setting the stage for their adventures ahead.
          </p>
          <p className="normal-22">
            <strong>Maximizing Staff Potential</strong> - Picture a workforce
            liberated from language barriers and extensive training. Our
            technology empowered the team to perform like seasoned pros from day
            one, drastically cutting costs while skyrocketing efficiency.
          </p>
          <p className="normal-22">
            <strong>Pioneering the Future of Airport Operations</strong> -
            Envision cutting-edge OCR technology nestled in the palm of your
            hand. Experience pinpoint accuracy and lightning-fast data
            management that will leave your competitors in awe and your
            passengers in wonder.
          </p>
        </div>

        <div className="col-5">
          <img src={GenAi2} alt="ny" className="py-2 w-100"></img>
          <img src={GenAi3} alt="nurse" className="py-2 w-100"></img>
          <img src={GenAi4} alt="bus" className="py-2 w-100"></img>
        </div>
      </div>
    </>
  );
};

export default Ocr;
