import React, { useState } from "react";
import "./GenAi.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import train from "../../res/images/train.jpeg";
import bus from "../../res/images/bus.jpeg";
import nurse from "../../res/images/nurse.jpeg";
import ny from "../../res/images/ny.jpeg";
import group51 from "../../res/images/Group 51.png";
import group50 from "../../res/images/Group 50.png";
import group55 from "../../res/images/Group 55.png";
import group57 from "../../res/images/Group 57.png";
import cency from "../../res/images/CENCY.png";
import GenAi1 from "../../res/images/GenAi1.png";
import GenAi2 from "../../res/images/GenAi2.png";
import GenAi3 from "../../res/images/GenAi3.png";
import GenAi4 from "../../res/images/GenAi4.png";
import fullscreen from "../../res/images/fullscreen.png";
import { Modal, Button } from "react-bootstrap";

const GenAi = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />
      <div className="strategy-containerGenAi">
        <div className="strategy-text-GenAi">
          <p className="generativeAi">Generative AI</p>

          <h1 className="CD">
            CHATBOT
            <br />
            DEVELOPMENT
          </h1>
          <br /> <br /> <br /> <br /> <br />
          <p className="GenAiSubheading">
            <strong>DOUBLE</strong> your organizational productivity<br/>Save upto 
            <strong> 25,000</strong> hours annually by streamlining<br/>
            communications
          </p>
        </div>
      </div>

      <div className="row mt-7 px">
      <div className="d-flex">
            <img
              src={fullscreen}
              className="mb-2 fullscreen"
              alt="full-screen"
              height={20}
              width={20}
              onClick={handleShow}
            ></img>
          </div>
        <div className="col-lg-12 col-md-12">
          <iframe
            style={{ height: "100vh" }}
            src="https://datapilot-chatbot.streamlit.app/?embedded=true"
            height="100vh"
            width="100%"
            frameBorder="0"
            scrolling="no"
            allowfullscreen
            title="Garment Defect Detection AI - model vs actual.mp4"
          ></iframe>
        </div>
      </div>

      {/* Steps involved */}
      <div className="row px mt-7">
        <div className="col-4 " style={{display:"flex"}}>
          <img src={group55} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            AI-powered chatbot that employees could use to learn about the
            company.
          </p>
        </div>

        <div className="col-4 " style={{display:"flex"}}>
          <img src={group50} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            A chatbot that serves as a personal assistant, providing current and
            new employees with immediate access to all relevant company
            methodologies, documentations, and policies.
          </p>
        </div>

        <div className="col-4" style={{display:"flex"}}>
          <img src={group51} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Guide employees through various onboarding processes, such as
            following task nomenclatures and logging in working hours within
            ClickUp. 
          </p>
        </div>
      </div>

      <div className="row px mt-7">
        <div className="col-6">
          <ul className="efficiency-p">
            <li>
            Slashed time wasted on repetitive queries by <strong className="c-50">50%</strong> freeing up staff for high-value work
            </li>
            <li>
            Reduced operational costs by <strong className="c-50">20%</strong> delivering major bottom-line savings 
            </li>
            <li>
            Available <strong className="c-50">24/7</strong> eliminating costly after-hours support staff 
            </li>
          </ul>
        </div>

        <div className="col-6">
          <img src={group57} alt="g57" className="w-100"></img>
        </div>

        <div className="col-10 mt-5">
          <img src={cency} alt="cency" className="w-100"></img>
        </div>
      </div>

      <div className="row px mt-7">
        <div className="col-7">
          <h1 className="industry">Future</h1>
          <p className="application">Applications</p>
          <img src={GenAi1} alt="train" className="w-100"></img>
          <p className="mt-4 normal-22">
            <strong>Personalized Client Portals</strong> - the chatbot can pave
            the way for AI-powered client portals that provide real-time project
            updates and hour-tracking insights.
          </p>
          <p className="normal-22">
            <strong>Proactive Issue Resolution</strong> - the chatbot can
            leverage predictive analytics to predict project bottlenecks and
            resource needs. 
          </p>
          <p className="normal-22">
            <strong>AI-driven HR Assistance</strong> - the chatbot's
            capabilities can be expanded to include AI-driven HR support, such
            as automated onboarding processes and real-time policy updates. This
            also includes personalized recommendations for employees to develop
            their career in the company, boosting employee engagement and
            retention.
          </p>
        </div>

        <div className="col-5">
          <img src={GenAi2} alt="ny" className="py-2 w-100"></img>
          <img src={GenAi3} alt="nurse" className="py-2 w-100"></img>
          <img src={GenAi4} alt="bus" className="py-2 w-100"></img>
        </div>
      </div>


      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>DP Chatbot</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "100vh" }}>
          <iframe
            // style={{ width: "70vw" }}
             src="https://datapilot-chatbot.streamlit.app/?embedded=true"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowfullscreen
            title="Garment Defect Detection AI - model vs actual.mp4"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GenAi;
