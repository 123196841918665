import axios from "axios";
import config from "../config";

axios.defaults.timeout = config.requestTimeoutDuration * 1000;
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 600;
  if (!expectedError) {
    console.log("logging error", error.status);
  }
  return Promise.reject(error);
});

function setHeaders(jwt) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  axios.defaults.headers.common["Content-Type"] = "application/json";
}
function setAuthHeader() {
  const token = localStorage.getItem(config.keys.token);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["Content-Type"] = "application/json";
}

function resetHeaders() {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
  axios.defaults.headers.common["Authorization"] = "";
  axios.defaults.headers.common["Content-Type"] = "";
}
function removeHeaders() {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
  // console.log(axios.defaults.headers)
  delete axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Content-Type"];
  delete axios.defaults.headers["Content-Type"];
  delete axios.defaults.headers.post["Content-Type"];
}
function printHeaders() {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
  console.log(axios.defaults);
}

const axiosObj = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setHeaders,
  setAuthHeader,
  resetHeaders,
  removeHeaders,
  printHeaders,
};

export default axiosObj;
