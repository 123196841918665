import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ChurnCaseStudy.css";
import MegaHeader from "../mega-header";
import group51 from "../../res/images/Group 51.png";
import group50 from "../../res/images/Group 50.png";
import group55 from "../../res/images/Group 55.png";
import GenAi1 from "../../res/images/c2.jpeg";
import GenAi2 from "../../res/images/c4.webp";
import GenAi3 from "../../res/images/c5.webp";
import GenAi4 from "../../res/images/GenAi4.png";
import ocrbtnbg from "../../res/images/ocrbtnbg.png";
import fullscreen from "../../res/images/fullscreen.png"
import ChurnInner from "../../res/images/ChurnInner.png"
import Churndashboard from "../../res/images/ChurnDashboard.png"

const ChurnCaseStudy = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <MegaHeader />
      <div className="strategy-containerChurn">
        <div className="strategy-text-Ocr">
          <h1 className="churn">Churn Prediction Analytics</h1>
          <br />
          <h1 className="churn-sub">Enhancing Subscriber Retention with Data-Driven Insights </h1>
        </div>
      </div>

      <div className="row mt-7 px">
        <div className="d-flex">
          <img src={fullscreen} className="mb-2 fullscreen" alt="full-screen" height={20} width={20}
            onClick={handleShow}
          ></img>

        </div>

        <div className="col-lg-12 col-md-12">
          <img src={Churndashboard} alt="c" style={{ width: "100%" }}></img>
        </div>
      </div>

      {/* Modal for Streamlit App */}
      <Modal show={showModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>Churn Prediction Analytics</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={Churndashboard} alt="c" style={{ width: "100%" }}></img>
        </Modal.Body>
      </Modal>

      {/* Steps involved */}
      <div className="row px mt-7">
        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group55} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
            Leverages machine learning to predict customer churn, enabling proactive engagement with at-risk subscribers
          </p>
        </div>

        <div className="col-4 " style={{ display: "flex" }}>
          <img src={group50} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
          Analyzes customer behavior and transaction history to identify patterns leading to churn. 
          </p>
        </div>

        <div className="col-4" style={{ display: "flex" }}>
          <img src={group51} alt="Ai" height={40} width={40}></img>
          <p className="icon-p">
          Provides risk segmentation and personalized intervention strategies to retain customers and boost lifetime value.
          </p>
        </div>
      </div>

      <div className="row px mt-7">
        <div className="col-7">
          <h1 className="industry">Future</h1>
          <p className="application">Applications</p>
          <img src={GenAi1} alt="train" className="w-100"></img>
          <p className="mt-4 normal-22">
            <strong>Revolutionizing Customer Engagement</strong> - Imagine a future where businesses can predict and prevent customer churn before it happens. Our AI-driven churn model empowers companies to deliver timely, personalized interventions, dramatically improving customer satisfaction and retention.
          </p>
          <p className="normal-22">
            <strong>Maximizing Retention Potential</strong> - Picture a system that not only identifies at-risk customers but also suggests the best course of action for each individual. With our predictive analytics, companies can optimize retention strategies, significantly lowering customer acquisition costs while increasing customer lifetime value
          </p>
          <p className="normal-22">
            <strong>Pioneering the Future of Predictive Analytics</strong> -
            Envision a future where real-time churn prediction is seamlessly integrated across all business systems. From marketing automation to customer service, experience a unified approach to retention that transforms churn into growth opportunities.
          </p>
        </div>

        <div className="col-5">
          <img src={GenAi2} alt="ny" className="py-2 w-100"></img>
          <img src={GenAi3} alt="nurse" className="py-2 w-100"></img>
          <img src={GenAi4} alt="bus" className="py-2 w-100"></img>
        </div>
      </div>
    </>
  );
};

export default ChurnCaseStudy;
